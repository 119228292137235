import React, { useMemo } from 'react';
import { type ViewField } from '@/types/api/views';
import { Col, Row } from '@/components/ui/row';
import { useTranslation } from '@/composables/translation';
import { useFormContext } from 'react-hook-form';
import { FormField } from '@/components/ui/form';
import { Combobox } from '@/components/ui/combobox';

interface Props {
    name: string;
    context?: { fields: ViewField[] };
}

export function ViewColumn(props: Props) {
    const form = useFormContext();
    const resourceName = form.watch('definition.resource_name');
    const fields = useMemo(
        () => (props.context?.fields ?? [])
            .filter(vf => vf.resource_name === resourceName),
        [props.context?.fields, resourceName]
    );
    return (<FormField
        name={props.name}
        render={(context) => {
            const { to } = useTranslation();
            const form = useFormContext();
            const fieldName = `${context.field.name}.field`;
            const field = form.watch(fieldName) as ViewField | null;
            return (
                <Row className="tw-flex">
                    <Col col={6}>
                        <Combobox<ViewField>
                            options={fields}
                            getOptionLabel={(opt) => to(opt.title)}
                            value={field}
                            onChange={(value) => form.setValue(fieldName, value)}
                        />
                    </Col>
                </Row>
            );
        }}
    />);
}
