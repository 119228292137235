import React, { useMemo, useRef, useState } from 'react';
import {
    type CrudApi,
    CrudDialog,
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    CrudTable
} from '@/components/ui/crud-table';
import { combine, type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import type { FormType } from '@/types/api/forms';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useLanguage } from '@/composables/language';
import { getForms, postFormStaticLink } from '@/composables/api';
import { ButtonCopy } from '@/components/ui/button-copy';
import { TranslateCell } from '@/components/ui/cells';
import { Button } from '@/components/ui/button';
import { Link2Icon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { InputCopy } from '@/components/ui/input-copy';
import { useUserStore } from '@/store/user';
import { BindProps } from '@/components/utils/BindProps';

interface StaticLinkValues {
    form_id: string;
    lang: Language;
    url?: string;
}

export function Forms() {
    const { t, ct, to } = useTranslation('settings.forms');
    const { options } = useLanguage();
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const crudApi = useRef<CrudApi>();
    const [forms, setForms] = useState<FormType[]>([]);
    const [staticLinkData, setStaticLinkData] = useState<StaticLinkValues>({ form_id: '', lang, url: '' });
    const [staticLinkOpen, setStaticLinkOpen] = useState(false);
    const groups = useMemo(
        () => forms.reduce<Record<string, TranslationObject>>(
            (obj, form) => {
                if (form.category) {
                    obj[form.category.name] = form.category.label;
                }
                return obj;
            }, {}
        ) ?? {},
        [forms]
    );
    const schema = useMemo<CrudSchema<FormType>>(() => [
        {
            id: 'id',
            type: CrudInputType.TEXT,
            columnDef: {
                id: 'id',
                accessorKey: 'id',
                header: t('table.id')
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            translate: true,
            create: false,
            columnDef: {
                id: 'title',
                accessorKey: 'title',
                header: t('table.title'),
                cell: TranslateCell({ to })
            }
        },
        {
            id: 'link.url',
            type: CrudInputType.TEXT,
            columnDef: {
                id: 'link.url',
                accessorKey: 'link.url',
                header: t('table.url'),
                size: 50,
                cell: ({ cell }) => {
                    const value = cell.getValue<string>();
                    return (<ButtonCopy className="tw-text-primary" size="icon" value={value} />);
                }
            }
        },
        {
            id: 'lang',
            type: CrudInputType.SELECT,
            name: ct('language'),
            required: true,
            col: 6,
            options,
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => opt.label
        } as CrudSelectInputOptions<FormType, typeof options[number]>
    ], []);
    const StaticLinkDialog = useMemo(() => BindProps(CrudDialog<StaticLinkValues, 'form_id'>(), {
        idKey: 'form_id',
        schema: [
            {
                id: 'form_id',
                type: CrudInputType.SELECT,
                name: ct('form'),
                col: 6,
                create: true,
                required: true,
                options: forms,
                getOptionValue: (opt) => opt.id,
                getOptionLabel: (opt) => to(opt.title),
                groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
            },
            {
                id: 'lang',
                type: CrudInputType.SELECT,
                name: ct('language'),
                col: 6,
                defaultValue: lang,
                required: true,
                options,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => opt.label
            } as CrudSelectInputOptions<StaticLinkValues, typeof options[number]>,
            {
                id: 'url',
                type: CrudInputType.CUSTOM,
                name: 'URL',
                render: ({ field }) => <InputCopy {...field} />
            }
        ]
    }), [forms]);
    return (
        <>
            <CardHeader>
                <CardTitle>Formulaires</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<FormType, 'id'>
                    idKey="id"
                    schema={schema}
                    apiRef={crudApi}
                    initialState={{
                        columnVisibility: { id: false },
                        sorting: [{ id: 'link.url', desc: false }]
                    }}
                    list={forms}
                    onChangeList={setForms}
                    actions={({ row }) =>
                        <>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <Button
                                        variant="ghost" size="icon"
                                        onClick={() => {
                                            setStaticLinkData({
                                                form_id: row.original.id,
                                                lang,
                                                url: ''
                                            });
                                            setStaticLinkOpen(true);
                                        }}
                                    >
                                        <Link2Icon />
                                    </Button>
                                </TooltipTrigger>
                                <TooltipContent>
                                    {t('table.actions.generate-link')}
                                </TooltipContent>
                            </Tooltip>
                        </>
                    }
                    onRead={() => getForms().then((res) => res.data)}
                />
            </CardContent>
            <StaticLinkDialog
                open={staticLinkOpen}
                onOpenChange={setStaticLinkOpen}
                value={staticLinkData}
                translation={{
                    title: combine('settings.forms.generate-link.title'),
                    submit: combine('settings.forms.generate-link.submit')
                }}
                onSubmit={(values) => postFormStaticLink(values)
                    .then((res) => {
                        setStaticLinkData({ ...values, url: res.data.url });
                        crudApi.current?.refreshList();
                    })
                }
            />
        </>
    );
}
