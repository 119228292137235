import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    type CrudApi,
    CrudDialog,
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    CrudTable, type ReadOptions
} from '@/components/ui/crud-table';
import { type FormListEntry } from '@/types/api/forms';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type Language, type TranslationObject, useTranslation } from '@/composables/translation';
import { type ColumnDef, type PaginationState } from '@tanstack/react-table';
import { getFormStatusFromList, getFormUrl } from '@/composables/neoform';
import { Button } from '@/components/ui/button';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { faFolderOpen, faUsers } from '@fortawesome/free-solid-svg-icons';
import { DateTime } from 'luxon';
import { getForms, getFormsList, getFormsListSearch, postCreateForm, postFormStaticLink } from '@/composables/api';
import { useLanguage } from '@/composables/language';
import { useQuery } from '@/composables/query';
import { DialogTrigger } from '@/components/ui/dialog';
import { Link2Icon } from '@radix-ui/react-icons';
import { InputCopy } from '@/components/ui/input-copy';
import { useUserStore } from '@/store/user';
import { type SearchType } from '@/components/ui/data-table';
import { FormStatusCell } from '@/components/ui/cells';
import { BindProps } from '@/components/utils/BindProps';

interface StaticLinkValues {
    form_id: string;
    lang: Language;
    url?: string;
}

export function Forms() {
    const { t, to, ct } = useTranslation('forms');
    const { options } = useLanguage();
    const { case_id, folder_id, ...params } = useQuery<{
        case_id: string;
        folder_id: string;
        search_index: string;
        search_value: string;
        search_type: string;
    }>();
    const { lang, org_id } = useUserStore(state => ({ lang: state.lang, org_id: state.user?.org_id }));

    const crudApi = useRef<CrudApi>();
    const [forms, setForms] = useState<any[]>([]);
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });
    const [staticLinkData, setStaticLinkData] = useState<StaticLinkValues>({ form_id: '', lang, url: '' });
    const [search, setSearch] = useState('');

    const groups = useMemo(
        () => forms.reduce(
            (obj, form) => {
                if (form.category) {
                    obj[form.category.name] = form.category.label;
                }
                return obj;
            }, {}
        ) ?? {},
        [forms]
    );

    const EditableCell: ColumnDef<FormListEntry>['cell'] = ({ cell }) => {
        const value = cell.getValue<string>();
        return (
            <Button className="!tw-p-0" variant="link" asChild>
                <Link to={`/folder/${value}`}>{value}</Link>
            </Button>
        );
    };

    const ActionCell: ColumnDef<FormListEntry>['cell'] = ({ row }) => {
        const url = getFormUrl(row.original.form_name, row.original.id);
        return (
            <div className="tw-flex tw-gap-1">
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            className="tw-text-muted-foreground"
                            variant="ghost" size="icon"
                            asChild
                        >
                            <a href={url}>
                                <FontAwesomeIcon icon={faFolderOpen}/>
                            </a>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('table.open')}
                    </TooltipContent>
                </Tooltip>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            className="tw-text-muted-foreground"
                            variant="ghost" size="icon"
                            asChild
                        >
                            <a href={`${url}/send`}>
                                <FontAwesomeIcon icon={faUsers} />
                            </a>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('table.send')}
                    </TooltipContent>
                </Tooltip>
            </div>
        );
    };

    const DateCell: ColumnDef<FormListEntry>['cell'] = ({ cell }) => {
        const value = cell.getValue<string>();
        const date = DateTime.fromISO(value);
        return (
            <>{date.isValid && date.toFormat('ff')}</>
        );
    };

    function fetchForms() {
        return getForms()
            .then((res) => {
                setForms(res.data
                    .map((f: any) => ({
                        id: f.id,
                        label: f.title,
                        form_name: f.form_name,
                        form_type: f.form_type,
                        category: f.category
                    })).sort((a: any, b: any) =>
                        (a.category?.name ?? 'default')
                            .localeCompare(b.category?.name ?? 'default'))
                );
            });
    }

    function fetchFormsDataList({ search_type, search_value: search }: ReadOptions) {
        const searchValue = search || params.search_value;
        const filter = {
            case_id,
            ticket_id: search || folder_id,
            ...(search && {
                client_email: search,
                folder_number: search,
                debtor_company_name: search,
                debtor_name: search,
                debtor_firstname: search
            })
        };
        const promise = searchValue
            ? getFormsListSearch({
                search_type: search_type === 'phrase' ? 'phrase' : (params.search_type as SearchType ?? 'text'),
                search_value: searchValue,
                args: JSON.stringify({ filter }),
                limit: pagination.pageSize,
                offset: pagination.pageIndex * pagination.pageSize
            })
            : getFormsList(filter, pagination.pageSize, pagination.pageIndex + 1);
        return promise.then((res) => {
            const data = res.data;
            if (typeof data.nb_rows === 'number') {
                setRowCount(data.nb_rows);
            }
            return data.data.map(d => ({
                ...d,
                date_created: DateTime
                    .fromFormat(d.date_created, 'yyyy-MM-dd HH:mm:ss')
                    .toISO()
            }));
        });
    }

    useEffect(() => {
        fetchForms().then();
    }, []);

    const schema = useMemo<CrudSchema<FormListEntry>>(() => {
        const baseSchema: CrudSchema<FormListEntry> = [
            {
                id: 'ticket_id',
                type: CrudInputType.TEXT,
                name: t('table.folder'),
                required: true,
                col: 6,
                columnDef: {
                    id: 'ticket_id',
                    accessorKey: 'ticket_id',
                    header: t('table.folder'),
                    cell: EditableCell
                }
            },
            {
                id: 'title',
                type: CrudInputType.TEXT,
                translate: true,
                create: false,
                columnDef: {
                    id: 'title',
                    accessorKey: 'title',
                    header: t('table.title'),
                    cell: ({ cell }) => <Tooltip>
                        <TooltipTrigger asChild>
                            <p className="tw-max-w-[200px] tw-truncate">
                                {to(cell.getValue<TranslationObject>())}
                            </p>
                        </TooltipTrigger>
                        <TooltipContent align="start">
                            {to(cell.getValue<TranslationObject>())}
                        </TooltipContent>
                    </Tooltip>,
                    sortingFn: 'auto'
                }
            },
            {
                id: 'lang',
                type: CrudInputType.SELECT,
                name: ct('language'),
                required: true,
                col: 6,
                options,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => opt.label
            } as CrudSelectInputOptions<FormListEntry, typeof options[number]>,
            {
                id: 'form_id',
                type: CrudInputType.SELECT,
                name: ct('form'),
                required: true,
                options: forms,
                getOptionValue: (opt) => opt.id,
                getOptionLabel: (opt) => to(opt.label),
                groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
            } as CrudSelectInputOptions<FormListEntry, any>,
            {
                id: 'date_created',
                type: CrudInputType.TEXT,
                name: t('table.date'),
                create: false,
                columnDef: {
                    id: 'date_created',
                    accessorKey: 'date_created',
                    header: t('table.date'),
                    filterFn: (row, columnId, filterValue: DateTime) => {
                        const value = row.getValue<string>(columnId);
                        const date = DateTime.fromISO(value).startOf('day');
                        return !filterValue?.isValid || filterValue.startOf('day').equals(date);
                    },
                    cell: DateCell
                }
            },
            {
                id: 'status',
                type: CrudInputType.TEXT,
                name: t('table.status'),
                create: false,
                columnDef: {
                    id: 'status',
                    accessorKey: 'status',
                    header: t('table.status'),
                    cell: FormStatusCell,
                    size: 50,
                    filterFn: (row, id, filterValue) => {
                        const value = row.getValue<FormListEntry['status']>(id);
                        const [status] = getFormStatusFromList(value);
                        return !filterValue || status === filterValue;
                    },
                    sortingFn: (rowA, rowB, columnId) => {
                        const [statusA, progressA] = getFormStatusFromList(rowA.getValue(columnId));
                        const [statusB, progressB] = getFormStatusFromList(rowB.getValue(columnId));
                        const statusDiff = statusA - statusB;
                        if (statusDiff === 0) {
                            return (progressA ?? 0) - (progressB ?? 0);
                        }
                        return statusDiff;
                    }
                }
            }
        ];

        if (org_id === '659f0e9eda489da3aa9c8a4c') {
            baseSchema.push(
                {
                    id: 'client_data.folderNumber',
                    type: CrudInputType.TEXT,
                    create: false,
                    columnDef: {
                        id: 'client_data.folderNumber',
                        accessorKey: 'client_data.folderNumber',
                        header: t('table.folderNumber')
                    }
                },
                {
                    id: 'client_data.debtor',
                    type: CrudInputType.TEXT,
                    create: false,
                    columnDef: {
                        id: 'client_data.debtor',
                        accessorKey: 'client_data.debtor',
                        header: t('table.debtor')
                    }
                }
            );
        }

        return baseSchema;
    }, [forms]);

    const DialogComponent = useMemo(
        () => CrudDialog<FormListEntry, 'id'>(),
        [schema]
    );

    const StaticLinkDialog = useMemo(() => BindProps(CrudDialog<StaticLinkValues, 'form_id'>(), {
        idKey: 'form_id',
        schema: [
            {
                id: 'form_id',
                type: CrudInputType.SELECT,
                name: ct('form'),
                col: 6,
                create: true,
                required: true,
                options: forms,
                getOptionValue: (opt) => opt.id,
                getOptionLabel: (opt) => to(opt.label),
                groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
            },
            {
                id: 'lang',
                type: CrudInputType.SELECT,
                name: ct('language'),
                col: 6,
                defaultValue: lang,
                required: true,
                options,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => opt.label
            } as CrudSelectInputOptions<StaticLinkValues, typeof options[number]>,
            {
                id: 'url',
                type: CrudInputType.CUSTOM,
                name: 'URL',
                render: ({ field }) => <InputCopy {...field} />
            }
        ]
    }), [forms]);

    return (
        <main className="tw-min-h-full tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{t('title')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<FormListEntry, 'id'>
                        useServerSearch
                        idKey="id"
                        schema={schema}
                        apiRef={crudApi}
                        actions={ActionCell}
                        toolbar={<>
                            <StaticLinkDialog
                                value={staticLinkData}
                                onSubmit={(values) => postFormStaticLink(values)
                                    .then((res) => {
                                        setStaticLinkData({ ...values, url: res.data.url });
                                    })
                                }
                            >
                                <DialogTrigger asChild>
                                    <Button variant="outline" className="tw-text-primary">
                                        <Link2Icon className="tw-mr-2" />
                                        Générer un lien statique
                                    </Button>
                                </DialogTrigger>
                            </StaticLinkDialog>
                        </>}
                        readDeps={[
                            case_id,
                            folder_id,
                            params.search_value,
                            params.search_index,
                            params.search_type
                        ]}
                        onCreate={(value) => postCreateForm({
                            form_id: value.form_id ?? '',
                            lang: value.lang ?? '',
                            ticket_id: value.ticket_id ?? ''
                        }).then()}
                        onRead={fetchFormsDataList}
                        dialogComponent={DialogComponent}
                        state={{ pagination }}
                        rowCount={!search ? rowCount : undefined}
                        onPaginationChange={setPagination}
                        manualPagination={!search}
                        search={search}
                        onChangeSearch={setSearch}
                    />
                </CardContent>
            </Card>
        </main>
    );
}
