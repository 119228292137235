import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useParams } from 'react-router-dom';
import { type View as ViewType } from '@/types/api/views';
import { getView, postRunView } from '@/composables/api';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import type { ColumnDef, PaginationState } from '@tanstack/react-table';
import { useTranslation } from '@/composables/translation';
import { CaseStatusCell, DateCell, DateCellRelative, FolderIdCell, TranslateCell } from '@/components/ui/cells';

export function View() {
    const { to } = useTranslation();
    const { view_id } = useParams() as { view_id: string };
    const [view, setView] = useState<ViewType>();
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });
    const DISPLAY_TYPES = useMemo<Record<string, ColumnDef<any>['cell']>>(() => ({
        case_status: CaseStatusCell,
        folder_id: FolderIdCell,
        translate: TranslateCell({ to }),
        date: DateCell,
        date_relative: DateCellRelative
    }), []);
    const schema = useMemo<CrudSchema<any>>(() => (view?.definition.columns ?? []).map(c => ({
        id: c.field.path,
        type: CrudInputType.TEXT,
        name: c.field.title,
        columnDef: {
            id: c.field.path,
            accessorKey: c.field.path,
            header: to(c.field.title),
            ...(c.field.description?.display_type &&
                DISPLAY_TYPES[c.field.description.display_type] &&
                { cell: DISPLAY_TYPES[c.field.description.display_type] }
            )
        }
    })), [view]);
    useEffect(() => {
        getView({ view_id })
            .then((res) => setView(res.data));
    }, [view_id]);
    return (
        <main className="tw-min-h-full !tw-p-4">
            <Card>
                <CardHeader>
                    <CardTitle>{to(view?.title, 'View')}</CardTitle>
                </CardHeader>
                <CardContent>
                    <CrudTable<any, '_id'>
                        idKey="_id"
                        schema={schema}
                        readDeps={[schema]}
                        onRead={() => {
                            if (!view?.definition?.compiled) {
                                return Promise.resolve([]);
                            }
                            return postRunView({
                                resource_name: view.definition.resource_name,
                                filters: view.definition.compiled.filters,
                                sort: view.definition.compiled.sort,
                                offset: pagination.pageIndex * pagination.pageSize,
                                limit: pagination.pageSize
                            }).then((res) => {
                                setRowCount(
                                    typeof res.data.nb_rows === 'number'
                                        ? res.data.nb_rows
                                        : res.data.data.length
                                );
                                return res.data.data;
                            });
                        }}
                        state={{ pagination }}
                        rowCount={rowCount}
                        onPaginationChange={setPagination}
                        manualPagination
                    />
                </CardContent>
            </Card>
        </main>
    );
}
