import React from 'react';
import { type ControllerProps, type FieldPath, type FieldValues } from 'react-hook-form';
import { CrudBillingModel } from '@/components/billing/CrudBillingModel';
import { useValidation } from '@/composables/validation';
import { useTranslation } from '@/composables/translation';
import { BindProps } from '@/components/utils/BindProps';
import { FormField } from '@/components/ui/form';

interface Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {}

export function BillingNeoform(props: Props) {
    const { t } = useTranslation('settings.billing.features.neoform');
    const { required } = useValidation();
    return (
        <FormField
            name={`${props.field.name}.rules.0.stages`}
            rules={{ validate: { required } }}
            render={BindProps(CrudBillingModel, {
                isUnitPriceTotal: true,
                translations: {
                    stage: t('stage'),
                    price: t('price')
                }
            }) as any}
        />
    );
}
