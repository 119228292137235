import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark, faDownload, faEye, faGripLines } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { FilePreview } from '@/components/neoform/helper/FilePreview';
import { getFileData } from '@/composables/api';
import { toast } from 'react-toastify';
import { downloadFileBase64, openFileURL, isURL } from '@/composables/utils';
import { type UploadedFile } from '@/components/neoform/inputs/InputDropContainer';
import { useTranslation } from '@/composables/translation';
import { TextFieldEdit } from '@/components/TextFieldEdit';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Spinner } from '@/components/ui/spinner';
import { cn } from '@/lib/utils';

interface Props {
    file: UploadedFile;
    amend?: boolean;
    standalone?: boolean;
    readonly?: boolean;
    onChange?: (value: UploadedFile) => void;
    onRemove?: () => void;
}

export function FileItem(props: Props) {
    const { t, ct } = useTranslation('neoform.drop-container');
    const [loading, setLoading] = useState(false);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [fileData, setFileData] = useState('');
    const isFileDataURL = isURL(fileData);

    function fetchFileData(): Promise<string> {
        const url = props.file.url ?? '';
        if (isURL(url)) {
            setFileData(url);
            return Promise.resolve(url);
        }
        return getFileData(props.file.token ?? '')
            .then(res => {
                if (!res.data.result) {
                    return Promise.reject();
                }
                const data = res.data.result.split(',')[1];
                setFileData(data);
                return data;
            });
    }

    function handleFilePreview() {
        if (fileData) {
            setPreviewOpen(true);
            return;
        }
        setLoading(true);
        fetchFileData()
            .then(() => setPreviewOpen(true))
            .catch(() => toast('Failed to fetch file', { type: 'error' }))
            .finally(() => setLoading(false));
    }

    function downloadFile(data: string) {
        const isUrl = isURL(data);
        if (isUrl) {
            openFileURL(data);
        } else {
            downloadFileBase64(props.file.name, props.file.content_type, data);
        }
    }

    function handleFileDownload() {
        if (fileData) {
            downloadFile(fileData);
            return;
        }
        setLoading(true);
        fetchFileData()
            .then((data) => downloadFile(data))
            .catch(() => toast('Failed to fetch file', { type: 'error' }))
            .finally(() => setLoading(false));
    }

    const fileType = (props.file.content_type.match(/\/(.+)$/)?.[1] ?? '').toUpperCase();

    return (
        <div
            className={cn(
                'tw-border tw-border-slate-300 tw-rounded-md',
                'tw-px-3 tw-py-2 tw-flex tw-items-center tw-cursor-grabbing'
            )}
        >
            <div className="tw-flex tw-items-center tw-gap-2">
                {props.file.url
                    ? <FontAwesomeIcon
                        icon={faGripLines}
                        className="tw-pr-1"
                    />
                    : <Spinner
                        className="tw-text-primary"
                        size="sm"
                    />
                }
                {!props.readonly && props.standalone &&
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <div className="tw-mr-1 tw-h-[20px]">
                                <Checkbox
                                    checked={props.file.include ?? false}
                                    onCheckedChange={(checked) => props.onChange?.({
                                        ...props.file,
                                        include: Boolean(checked)
                                    })}
                                />
                            </div>
                        </TooltipTrigger>
                        <TooltipContent>
                            {t('select')}
                        </TooltipContent>
                    </Tooltip>
                }
                {(!props.readonly && props.standalone)
                    ? <TextFieldEdit
                        value={props.file.name}
                        onChange={(value) => {
                            if (value.trim()) {
                                props.onChange?.({
                                    ...props.file,
                                    name: value.trim()
                                });
                            }
                        }}
                        formatter={(name) =>
                            <span className="tw-text-primary">
                                {name}
                            </span>
                        }
                    />
                    : <span className="tw-text-primary tw-ml-2">
                        {props.file.name}
                    </span>
                }
                {props.standalone && props.amend &&
                    <div className="!tw-ml-2 tw-flex tw-items-center tw-gap-2">
                        <Checkbox
                            id={`${props.file._id}-amend`}
                            checked={props.file.amend ?? false}
                            onCheckedChange={(checked) => props.onChange?.({
                                ...props.file,
                                amend: Boolean(checked)
                            })}
                        />
                        <Label htmlFor={`${props.file._id}-amend`}>
                            {ct('amend')}
                        </Label>
                    </div>
                }
            </div>
            <div className="!tw-ml-auto tw-mr-2 tw-text-muted-foreground">{fileType}</div>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        type="button" variant="ghost" size="icon"
                        className="!tw-rounded-full tw-text-primary"
                        disabled={!props.file.url}
                        loading={loading}
                        onClick={handleFilePreview}
                    >
                        <FontAwesomeIcon icon={faEye} />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {t('preview')}
                </TooltipContent>
            </Tooltip>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button
                        type="button" variant="ghost" size="icon"
                        className="!tw-rounded-full tw-text-primary"
                        disabled={!props.file.url}
                        loading={loading}
                        onClick={handleFileDownload}
                    >
                        <FontAwesomeIcon icon={faDownload} />
                    </Button>
                </TooltipTrigger>
                <TooltipContent>
                    {t('download')}
                </TooltipContent>
            </Tooltip>
            {!props.readonly &&
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            type="button" variant="ghost" size="icon"
                            className="!tw-rounded-full tw-text-destructive"
                            onClick={() => props.onRemove?.()}
                        >
                            <FontAwesomeIcon icon={faCircleXmark}/>
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {t('remove')}
                    </TooltipContent>
                </Tooltip>
            }
            <FilePreview
                file={{
                    mimeType: props.file.content_type,
                    name: props.file.name,
                    ...(isFileDataURL && { url: fileData }),
                    ...(!isFileDataURL && { data: fileData })
                }}
                open={previewOpen}
                onOpenChange={(v) => setPreviewOpen(v)}
            />
        </div>
    );
}
