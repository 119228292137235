import { type User, type UserGroup } from '@/types/api/user';
import { useMemo } from 'react';
import { useTranslation } from '@/composables/translation';

interface Options {
    users: User[];
    groups: UserGroup[];
    includeCurrentUser?: boolean;
}

const ASSIGN_LABELS = {
    user: {
        fr: 'Utilisateurs',
        en: 'Users'
    },
    group: {
        fr: 'Groupes',
        en: 'Groups'
    }
} as const;

export interface Assign {
    type: 'user' | 'group';
    value: string;
    label: string;
}

export function useAssignment(opts: Options) {
    const { to } = useTranslation();
    const options = useMemo<Assign[]>(
        () => [
            ...opts.groups
                .filter(g => g.category !== 'speciality')
                .map((g) => ({
                    type: 'group' as const,
                    value: g._id.$oid,
                    label: to(g.name)
                })),
            ...opts.users
                .map((u) => ({
                    type: 'user' as const,
                    value: u.id.$oid,
                    label: `${u.firstname} ${u.lastname}`
                })),
            ...(opts.includeCurrentUser
                ? [{
                    type: 'user' as const,
                    value: 'self',
                    label: to({
                        en: 'Connected user',
                        fr: 'Utilisateur connecté'
                    })
                }]
                : [])
        ],
        [opts.users, opts.groups]
    );
    return {
        options,
        getOptionLabel: (opt: Assign) => opt.label,
        getOptionValue: (opt: Assign) => opt.value,
        groupBy: (opt: Assign) => to(ASSIGN_LABELS[opt.type])
    };
}
