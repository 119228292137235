import React, { useEffect, useMemo, useState } from 'react';
import { type UpdateUserDto, type UserGroup } from '@/types/api/user';
import { CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { getOrgGroups, getOrgUsers, postAdminUpdateUser, postCreateUser } from '@/composables/api';
import { useError } from '@/composables/error';
import { useGroups } from '@/composables/groups';
import { BadgeListCell, CheckboxCell } from '@/components/ui/cells';
import { BookmarkFilledIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { ROLE_OPTIONS } from '@/composables/role';
import { List } from '@/components/fields/List';
import { PricingLevel } from '@/components/users/PricingLevel';

export function Users() {
    const { t, to, ct } = useTranslation('settings.users');
    const { handleNetworkError } = useError();

    const [groups, setGroups] = useState<UserGroup[]>([]);
    const { groupBy } = useGroups({ groups });

    useEffect(() => {
        getOrgGroups()
            .then((res) => setGroups(res.data))
            .catch(handleNetworkError);
    }, []);

    const GroupCell = useMemo(() => BadgeListCell<UpdateUserDto, UserGroup>({
        getter: ({ cell }) =>
            (cell.getValue<UpdateUserDto['groups']>() ?? [])
                .map<UserGroup>((gid) => groups.find(g => g._id.$oid === gid) as UserGroup)
                .filter(g => !!g),
        getOptionValue: (opt) => opt._id.$oid,
        getOptionLabel: (opt) => to(opt.name),
        className: 'tw-w-[200px]',
        badgeProps: {
            size: 'sm'
        },
        slots: {
            empty: <Tooltip>
                <TooltipTrigger>
                    <BookmarkFilledIcon className="tw-text-destructive" />
                </TooltipTrigger>
                <TooltipContent>
                    Aucun groupe
                </TooltipContent>
            </Tooltip>
        }
    }), [groups]);

    const schema = useMemo<CrudSchema<UpdateUserDto>>(() => [
        {
            id: 'id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            readonly: true,
            update: false,
            columnDef: {
                id: 'id',
                header: t('table.id'),
                accessorKey: 'id'
            }
        },
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: t('table.active'),
            defaultValue: true,
            create: false,
            columnDef: {
                id: 'active',
                header: t('table.active'),
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'firstname',
            type: CrudInputType.TEXT,
            name: t('table.first-name'),
            col: 6,
            required: true,
            columnDef: {
                id: 'firstname',
                header: t('table.first-name'),
                accessorKey: 'firstname'
            }
        },
        {
            id: 'lastname',
            type: CrudInputType.TEXT,
            name: t('table.last-name'),
            col: 6,
            required: true,
            columnDef: {
                id: 'lastname',
                header: t('table.last-name'),
                accessorKey: 'lastname'
            }
        },
        {
            id: 'username',
            type: CrudInputType.TEXT,
            name: t('table.email'),
            readonly: true,
            create: false
        },
        {
            id: 'username',
            type: CrudInputType.TEXT,
            name: t('table.email'),
            required: true,
            update: false,
            columnDef: {
                id: 'username',
                header: t('table.email'),
                accessorKey: 'username'
            }
        },
        {
            id: 'role',
            type: CrudInputType.SELECT,
            name: t('table.role'),
            required: true,
            options: ROLE_OPTIONS,
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => to(opt.label),
            columnDef: {
                id: 'role',
                header: t('table.role'),
                accessorKey: 'role',
                cell: ({ cell }) => to(
                    ROLE_OPTIONS.find(r => r.value === cell.getValue<string>())?.label,
                    cell.getValue<string>()
                )
            }
        },
        {
            id: 'groups',
            type: CrudInputType.SELECT,
            name: t('table.groups'),
            multiple: true,
            options: groups,
            getOptionValue: (opt) => opt._id.$oid,
            getOptionLabel: (opt) => to(opt.name),
            groupBy,
            columnDef: {
                id: 'groups',
                header: t('table.groups'),
                accessorKey: 'groups',
                cell: GroupCell
            }
        },
        {
            id: 'calendar',
            type: CrudInputType.HEADER,
            name: t('table.calendar')
        },
        {
            id: 'timezone',
            type: CrudInputType.SELECT,
            name: t('table.timezone'),
            col: 4,
            options: Intl.supportedValuesOf('timeZone'),
            getOptionLabel: (opt) => opt
                .replaceAll('/', ' / ')
                .replaceAll('_', ' ')
        },
        {
            id: 'working_start',
            type: CrudInputType.TIME,
            name: t('table.working-start'),
            col: 4
        },
        {
            id: 'working_end',
            type: CrudInputType.TIME,
            name: t('table.working-end'),
            col: 4
        },
        {
            id: 'apply_book_limit',
            type: CrudInputType.CHECKBOX,
            name: t('table.apply-book-limit')
        },
        {
            id: 'time-tracking',
            type: CrudInputType.HEADER,
            name: t('table.pricing-levels')
        },
        {
            id: 'params.pricing_levels',
            type: CrudInputType.CUSTOM,
            render: List(PricingLevel, { addButtonText: ct('add') })
        }
    ], [groups, groupBy]);

    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<UpdateUserDto, 'id'>
                    idKey="id"
                    schema={schema}
                    initialState={{ columnVisibility: { id: false } }}
                    onRead={() =>
                        getOrgUsers({ inactive: true })
                            .then((res) => res.data.map((u) => ({
                                ...u,
                                id: u.id.$oid,
                                active: Boolean(u.active),
                                groups: u.groups.map((g) => g._id.$oid),
                                working_start: typeof u.working_start === 'string'
                                    ? DateTime.fromFormat(u.working_start, 'HH:mm:ss').toISO()
                                    : undefined,
                                working_end: typeof u.working_end === 'string'
                                    ? DateTime.fromFormat(u.working_end, 'HH:mm:ss').toISO()
                                    : undefined
                            })))
                    }
                    onCreate={(value) =>
                        postCreateUser(value).then(() => {
                            toast.success(ct('messages.success'));
                        })
                    }
                    // onDelete={(values) => {
                    //     return delay(1000);
                    // }}
                    onUpdate={(value) => {
                        const workingStartDate = DateTime.fromISO(value.working_start ?? '');
                        const workingEndDate = DateTime.fromISO(value.working_end ?? '');
                        const working_start = workingStartDate.isValid
                            ? workingStartDate.toFormat('HH:mm:ss')
                            : undefined;
                        const working_end = workingStartDate.isValid
                            ? workingEndDate.toFormat('HH:mm:ss')
                            : undefined;
                        return postAdminUpdateUser({
                            ...value,
                            working_start,
                            working_end
                        }).then(() => {
                            toast.success(ct('messages.success'));
                        });
                    }}
                />
            </CardContent>
        </>
    );
}
