import { Button, type ButtonProps } from '@/components/ui/button';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { getOAuthTokenStatus, getOAuthTokenLogout } from '@/composables/api';
import { useControllableState } from '@/composables/controllable';
import { LinkBreak2Icon } from '@radix-ui/react-icons';
import { useTranslation } from '@/composables/translation';

export interface OAuthOptions {
    url: string;
    params?: Record<string, string>;
    client_id: string;
    provider: string;
    plugin_name: string;
    scope: string;
    redirect: string;
}

interface Props extends ButtonProps {
    oauth: OAuthOptions;
    connected?: boolean;
    onConnectedChange?: React.Dispatch<React.SetStateAction<boolean>>;
}

export function OAuthRedirectButton({ oauth, connected, onConnectedChange, ...props }: Props) {
    const { ct } = useTranslation();
    const [isHovered, setIsHovered] = useState(false);
    const [isConnected, setIsConnected] = useControllableState(false, connected, onConnectedChange);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        getOAuthTokenStatus({
            plugin_name: oauth.plugin_name
        }).then((res) => setIsConnected(
            res.data.status &&
            res.data.provider === oauth.provider
        ));
    }, [oauth.plugin_name, oauth.provider]);
    const url = useMemo(() => axios.getUri({
        baseURL: oauth.url,
        params: {
            response_type: 'code',
            client_id: oauth.client_id,
            redirect_uri: `${import.meta.env.VITE_APP_URL}/plugin/oauth`,
            scope: oauth.scope,
            state: JSON.stringify({
                provider: oauth.provider,
                plugin_name: oauth.plugin_name,
                redirect: oauth.redirect
            }),
            ...oauth.params
        }
    }), [oauth]);
    function handleLogout() {
        setLoading(true);
        getOAuthTokenLogout({ plugin_name: oauth.plugin_name })
            .then(() => setIsConnected(false))
            .finally(() => setLoading(false));
    }
    return (
        <Button
            {...props}
            variant={isConnected ? isHovered ? 'destructive' : 'success' : 'outline'}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onClick={isConnected ? handleLogout : undefined}
            loading={loading}
            asChild={!isConnected}
        >
            {isConnected
                ? isHovered
                    ? <>
                        <LinkBreak2Icon className="tw-mr-2" />
                        {ct('disconnect')}
                    </>
                    : props.children
                : <Link
                    target="_blank"
                    to={url}
                >
                    {props.children}
                </Link>}
        </Button>
    );
}
