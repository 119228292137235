import type { LoaderFunction } from '@remix-run/router/utils';
import { postOAuthToken } from '@/composables/api';
import { redirect } from 'react-router-dom';

export const PluginOAuthLoader: LoaderFunction = async({ request }) => {
    const url = new URL(request.url);
    const state = JSON.parse(url.searchParams.get('state') ?? '{}');
    try {
        await postOAuthToken({
            code: url.searchParams.get('code') ?? '',
            provider: state.provider,
            plugin_name: state.plugin_name,
            redirect_uri: `${import.meta.env.VITE_APP_URL}/plugin/oauth`
        });
    } catch (err) {}
    return redirect(state.redirect ?? '/settings/account');
};
