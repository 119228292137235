import React, { useEffect, useMemo, useState } from 'react';
import {
    CrudDialog, CrudInputType, type CrudSchema, CrudTable, type UpdateDialogProps
} from '@/components/ui/crud-table';
import { type GetViewFieldsOptions, type View, type ViewField } from '@/types/api/views';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { combine, useTranslation } from '@/composables/translation';
import { CheckboxCell, DateCell, TranslateCell } from '@/components/ui/cells';
import { getOrgGroups, getOrgUsers, getViewFields, getViews, postCreateView, putUpdateView } from '@/composables/api';
import { OpenInNewWindowIcon } from '@radix-ui/react-icons';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Link } from 'react-router-dom';
import { compileView, useViews } from '@/composables/views';
import { ViewFilter } from '@/components/psj/views/ViewFilter';
import { List } from '@/components/psj/views/List';
import { ViewSort } from '@/components/psj/views/ViewSort';
import { ViewColumn } from '@/components/psj/views/ViewColumn';
import { type User, type UserGroup } from '@/types/api/user';
import { BindProps } from '@/components/utils/BindProps';

export const VIEW_TYPES = [
    { value: 'case', label: combine('settings.views.crud-dialog.type.folders') },
    { value: 'party', label: combine('settings.views.crud-dialog.type.clients') },
    { value: 'form', label: combine('settings.views.crud-dialog.type.forms') }
];

export function Views() {
    const { t, to } = useTranslation('settings.views');
    const [fields, setFields] = useState<ViewField[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [groups, setGroups] = useState<UserGroup[]>([]);
    const schema = useMemo<CrudSchema<View>>(() => [
        {
            id: '_id',
            type: CrudInputType.TEXT,
            name: t('table.id'),
            readonly: true,
            columnDef: {
                id: '_id',
                accessorKey: '_id',
                header: t('table.id')
            }
        },
        {
            id: 'active',
            type: CrudInputType.CHECKBOX,
            name: 'Actif',
            columnDef: {
                id: 'active',
                header: 'Actif',
                accessorKey: 'active',
                cell: CheckboxCell
            }
        },
        {
            id: 'title',
            type: CrudInputType.TEXT,
            name: t('table.title'),
            columnDef: {
                id: 'title',
                accessorKey: 'title',
                header: t('table.title'),
                cell: TranslateCell({ to })
            }
        },
        {
            id: 'definition.resource_name',
            type: CrudInputType.TEXT,
            name: t('table.type'),
            columnDef: {
                id: 'definition.resource_name',
                accessorKey: 'definition.resource_name',
                header: t('table.type'),
                cell: ({ cell }) => to(VIEW_TYPES.find(t => t.value === cell.getValue<string>())?.label)
            }
        },
        {
            id: 'updated_at',
            type: CrudInputType.TEXT,
            name: t('table.updated_at'),
            columnDef: {
                id: 'updated_at',
                header: t('table.updated_at'),
                accessorKey: 'updated_at',
                cell: DateCell
            }
        },
        {
            id: 'created_at',
            type: CrudInputType.TEXT,
            name: t('table.created_at'),
            columnDef: {
                id: 'created_at',
                header: t('table.created_at'),
                accessorKey: 'created_at',
                cell: DateCell
            }
        }
    ], []);
    const DialogComponent = useMemo(
        () => BindProps(
            CrudDialog<View, '_id'>({
                className: 'md:tw-max-w-4xl'
            }),
            {
                idKey: '_id',
                schema: [
                    {
                        id: 'definition.resource_name',
                        type: CrudInputType.SELECT,
                        name: 'Type',
                        col: 6,
                        options: VIEW_TYPES,
                        getOptionValue: opt => opt.value,
                        getOptionLabel: opt => to(opt.label)
                    },
                    {
                        id: 'title',
                        type: CrudInputType.TEXT,
                        col: 6,
                        name: t('table.title'),
                        translate: true
                    },
                    {
                        id: 'section.filters',
                        type: CrudInputType.HEADER,
                        name: 'Filters'
                    },
                    {
                        id: 'definition.filters.or',
                        type: CrudInputType.CUSTOM,
                        name: 'Match any of the following',
                        render: List(ViewFilter, {
                            addButtonText: 'New Condition',
                            context: { fields, users, groups }
                        })
                    },
                    {
                        id: 'definition.filters.and',
                        type: CrudInputType.CUSTOM,
                        name: 'Match all of the following',
                        render: List(ViewFilter, {
                            addButtonText: 'New Condition',
                            context: { fields, users, groups }
                        })
                    },
                    {
                        id: 'section.columns',
                        type: CrudInputType.HEADER,
                        name: 'Columns'
                    },
                    {
                        id: 'definition.columns',
                        type: CrudInputType.CUSTOM,
                        render: List(ViewColumn, {
                            addButtonText: 'New Column',
                            className: '!-tw-mt-2',
                            context: { fields }
                        })
                    },
                    {
                        id: 'section.sort',
                        type: CrudInputType.HEADER,
                        name: 'Sort'
                    },
                    {
                        id: 'definition.sort',
                        type: CrudInputType.CUSTOM,
                        render: List(ViewSort, {
                            addButtonText: 'New Sort',
                            className: '!-tw-mt-2',
                            context: { fields }
                        })
                    }
                ] as CrudSchema<View>
            }
        ) as React.FC<UpdateDialogProps<View, '_id'>>,
        [fields, users, groups]
    );
    const { buildViewUrl } = useViews();

    useEffect(() => {
        Promise.all(VIEW_TYPES.map(t => getViewFields({
            view_type: t.value as GetViewFieldsOptions['view_type']
        })))
            .then((res) => {
                setFields(res.map((r, idx) => r.data.map(vf => (
                    {
                        ...vf,
                        resource_name: VIEW_TYPES[idx].value
                    }
                ))).flat());
            });
        Promise.all([getOrgUsers(), getOrgGroups()])
            .then(([users, groups]) => {
                setUsers(users.data);
                setGroups(groups.data);
            });
    }, []);

    return (
        <Card>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<View, '_id'>
                    idKey="_id"
                    schema={schema}
                    initialState={{ columnVisibility: { _id: false } }}
                    actions={({ row }) => <>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <Button variant="ghost" size="icon" asChild>
                                    <Link to={buildViewUrl(row.original)} target="_blank">
                                        <OpenInNewWindowIcon/>
                                    </Link>
                                </Button>
                            </TooltipTrigger>
                            <TooltipContent>
                                Ouvrir dans un nouvel onglet
                            </TooltipContent>
                        </Tooltip>
                    </>}
                    onRead={() => getViews().then(res => res.data)}
                    onUpdate={(value) => {
                        value.name = to(value.title);
                        value.definition.compiled = compileView(
                            value.definition.filters.or,
                            value.definition.filters.and,
                            value.definition.sort,
                            value.definition.columns
                        );
                        return putUpdateView(value).then();
                    }}
                    onCreate={(value) => {
                        value.name = to(value.title);
                        value.definition.compiled = compileView(
                            value.definition.filters.or,
                            value.definition.filters.and,
                            value.definition.sort,
                            value.definition.columns
                        );
                        return postCreateView(value).then();
                    }}
                    dialogComponent={DialogComponent}
                />
            </CardContent>
        </Card>
    );
}
