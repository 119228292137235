import { type User, type UserGroup } from '@/types/api/user';
import React from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { FormControl, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Combobox } from '@/components/ui/combobox';
import { useAssignment } from '@/composables/assignment';

interface Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
    users: User[];
    groups: UserGroup[];
    label?: string;
}

export function AssignSelect({ users, groups, label, field: { ref, ...field }, fieldState }: Props) {
    const {
        options,
        getOptionLabel,
        getOptionValue,
        groupBy
    } = useAssignment({ users, groups, includeCurrentUser: true });
    return (
        <FormItem>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
                <Combobox
                    options={options}
                    getOptionLabel={getOptionLabel}
                    getOptionValue={getOptionValue}
                    groupBy={groupBy}
                    {...field}
                    innerRef={ref}
                    error={!!fieldState.error}
                />
            </FormControl>
            <FormMessage />
        </FormItem>
    );
}
