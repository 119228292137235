import React from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { DateTime } from 'luxon';
import { DatePicker } from '@/components/ui/date-picker';
import { TimePicker } from '@/components/ui/time-picker';
import { getFieldError } from '@/composables/validation';

const COMPONENTS = {
    date: DatePicker,
    datetime: DatePicker,
    time: TimePicker
};

interface Props {
    type?: keyof typeof COMPONENTS;
    minDate?: 'now' | string;
    maxDate?: 'now' | string;
}

function parseDateProp(value: 'now' | string | undefined): Date | undefined {
    if (!value) {
        return undefined;
    }
    if (value === 'now') {
        return new Date();
    }
    const date = DateTime.fromISO(value);
    return date.isValid ? date.toJSDate() : undefined;
}

export function InputDateTime(props: Props & NeoFormComponentProps) {
    const { form, id, getValidationRules } = useNeoForm();
    const validate = getValidationRules(props);
    const type = props.type ?? 'datetime';
    return (
        <FormField
            name={id}
            defaultValue={props.default}
            render={({ field, formState }) => {
                let value = field.value;
                if (typeof value === 'string') {
                    value = DateTime.fromISO(value);
                    if (!value.isValid) {
                        value = null;
                    }
                    field.onChange(value);
                    form?.set(id, value);
                }
                const error = !!getFieldError(id, formState);
                return (
                    <FormItem>
                        <FormControl>
                            {React.createElement(COMPONENTS[type], {
                                id,
                                disabled: props.readonly,
                                error,
                                calendarProps: {
                                    fromDate: parseDateProp(props.minDate),
                                    toDate: parseDateProp(props.maxDate)
                                },
                                ...field,
                                ref: (ref) => {
                                    field.ref(ref);
                                    form?.ref(id, ref);
                                },
                                value: value || null,
                                onChange: (v) => {
                                    field.onChange(v);
                                    form?.set(id, v);
                                }
                            })}
                        </FormControl>
                        <FormMessage />
                    </FormItem>
                );
            }}
            rules={{ validate }}
        />
    );
}
