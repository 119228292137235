import React, { useCallback, useEffect, useState } from 'react';
import { Link, type Location, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTabStore } from '@/store/tabs';
import { PSJTab } from '@/components/psj/PSJTab';
import { type Tab, iconMap } from '@/types/tabs';
import { combine, useTranslation } from '@/composables/translation';
import { useQuery } from '@/composables/query';
import { cn } from '@/lib/utils';
import { PlusIcon } from '@radix-ui/react-icons';
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { Button } from '@/components/ui/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen, faHistory, faListCheck, faNoteSticky, faUser, faXmark } from '@fortawesome/free-solid-svg-icons';
import qs from 'query-string';
import { type View } from '@/types/api/views';
import { getViews } from '@/composables/api';
import { useViews } from '@/composables/views';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import {
    Sidebar,
    SidebarContent, SidebarFooter,
    SidebarHeader,
    SidebarMenu, SidebarMenuButton,
    SidebarMenuItem, SidebarMenuSub, SidebarMenuSubItem,
    SidebarRail,
    SidebarTrigger, useSidebar
} from '@/components/ui/sidebar';
import { CardTitle } from '@/components/ui/card';
import { DismissableLayer } from '@radix-ui/react-dismissable-layer';
import { ChevronRight } from 'lucide-react';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import axios from 'axios';
import { Feature, useFeatures } from '@/composables/features';

type TabFunction<TParams = any, TQuery = any> =
    (location: Location, params: TParams, query: TQuery) => Partial<Tab>;

const TAB_TYPES: Record<string, TabFunction> = {
    '/dashboard': () => ({
        type: 'view',
        label: combine('common.folders')
    }),
    '/clients': () => ({
        type: 'view',
        label: combine('common.clients')
    }),
    '/forms': () => ({
        type: 'view',
        label: combine('common.forms')
    }),
    '/folder': (_, { folder_id }) => ({
        type: 'folder',
        label: folder_id
    }),
    '/unassigned': () => ({
        type: 'notes',
        label: combine('common.unassigned-notes')
    })
};

function getViewIcon(view: View) {
    switch (view.definition.resource_name) {
    case 'case':
        return (
            <FontAwesomeIcon
                className="tw-mr-2"
                width="1em"
                icon={faFolderOpen}
            />
        );
    case 'party':
        return (
            <FontAwesomeIcon
                className="tw-mr-2"
                width="1em"
                icon={faUser}
            />
        );
    case 'form':
        return (
            <FontAwesomeIcon
                className="tw-mr-2"
                width="1em"
                icon={faListCheck}
            />
        );
    }
}

export function PSJLayout() {
    const navigate = useNavigate();
    const location = useLocation();
    const params = useParams();
    const query = useQuery<any>();
    const { to, t } = useTranslation('psj.layout');
    const { isFeatureEnabled } = useFeatures();
    const { buildViewUrl } = useViews();
    const { toggleSidebar, setOpen } = useSidebar();
    const { tabs, addTab, removeTab, reset } = useTabStore((state) => ({
        tabs: state.tabs,
        addTab: state.addTab,
        removeTab: state.removeTab,
        reset: state.reset,
        history: state.history
    }));
    const [, setSearch] = useSearchParams();
    const [views, setViews] = useState<View[]>([]);

    useEffect(() => {
        getViews({ limit: 10000, active: true })
            .then(res => setViews(res.data));
    }, []);

    useEffect(() => {
        addTab(getTab(location, params, query));
    }, [location.pathname, query]);

    function getTab(location: Location, params: any, query: any): Tab {
        const TAB_TITLE_KEY = 'tab';
        const title = query[TAB_TITLE_KEY];
        delete query[TAB_TITLE_KEY];
        let search = qs.stringify(query);
        search = search ? `?${search}` : search;
        if (search !== location.search) {
            setSearch(search, { replace: true });
        }
        const entry = Object.entries(TAB_TYPES)
            .find(([path]) => location.pathname.startsWith(path));
        return {
            type: 'view',
            label: location.pathname,
            url: location.pathname + search,
            ...entry?.[1]?.(location, params, query),
            ...(title && { label: title })
        };
    }

    const onDeleteTab = useCallback((url: string) => {
        const nextTab = removeTab(url);
        if (url === location.pathname + location.search) {
            const nextUrl = nextTab?.url ?? '/dashboard';
            navigate(nextUrl, { replace: true });
        }
    }, [location.pathname, location.search, navigate, removeTab]);

    const closeAllTabs = useCallback(() => {
        if (tabs.length > 1) {
            reset();
            location.pathname === '/dashboard' ? addTab(getTab(location, params, query)) : navigate('/dashboard');
        }
    }, [tabs.length, reset, location.pathname, addTab, getTab, navigate, params, query]);

    useEffect(() => {
        setOpen(false);
    }, [location]);

    return (
        <>
            <Sidebar variant="nogap" className="tw-top-[60px] !tw-h-[calc(100svh_-_60px)] tw-border-r">
                <SidebarHeader className="tw-px-3 tw-pt-3">
                    <CardTitle className="!tw-text-base tw-flex tw-items-center">
                        <span>{t('views.title')}</span>
                        <SidebarTrigger className="tw-ml-auto" />
                    </CardTitle>
                </SidebarHeader>
                <DismissableLayer
                    onPointerDownOutside={() => setOpen(false)}
                    onDismiss={() => setOpen(false)}
                >
                    <SidebarContent className="tw-p-1 [&_a]:tw-w-full">
                        <SidebarMenu>
                            <Collapsible defaultOpen className="tw-group/collapsible">
                                <SidebarMenuItem>
                                    <CollapsibleTrigger asChild>
                                        <SidebarMenuButton>
                                            <FontAwesomeIcon
                                                className="tw-mr-2"
                                                width="1em"
                                                icon={faFolderOpen}
                                            />
                                            <span>{t('views.folders.title')}</span>
                                            <ChevronRight className={cn(
                                                'tw-ml-auto tw-transition-transform',
                                                'group-data-[state=open]/collapsible:tw-rotate-90'
                                            )} />
                                        </SidebarMenuButton>
                                    </CollapsibleTrigger>
                                    <CollapsibleContent>
                                        <SidebarMenuSub>
                                            <SidebarMenuSubItem>
                                                <SidebarMenuButton
                                                    variant={
                                                        location.pathname === '/dashboard' &&
                                                        location.search === ''
                                                            ? 'outline'
                                                            : 'default'
                                                    }
                                                    className={cn(
                                                        location.pathname === '/dashboard' &&
                                                        location.search === '' &&
                                                        'tw-bg-primary tw-text-primary-foreground ' +
                                                        'hover:!tw-bg-primary ' +
                                                        'hover:!tw-text-primary-foreground'
                                                    )}
                                                    asChild
                                                >
                                                    <Link
                                                        to={axios.getUri({
                                                            baseURL: '/dashboard',
                                                            params: {
                                                                tab: t('views.folders.tabs.mine')
                                                            }
                                                        })}
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        {t('views.folders.mine')}
                                                    </Link>
                                                </SidebarMenuButton>
                                            </SidebarMenuSubItem>
                                            <SidebarMenuSubItem>
                                                <SidebarMenuButton
                                                    variant={
                                                        location.pathname === '/dashboard' &&
                                                        location.search.startsWith('?my_folders=false')
                                                            ? 'outline'
                                                            : 'default'
                                                    }
                                                    className={cn(
                                                        location.pathname === '/dashboard' &&
                                                        location.search.startsWith('?my_folders=false') &&
                                                        'tw-bg-primary tw-text-primary-foreground ' +
                                                        'hover:!tw-bg-primary ' +
                                                        'hover:!tw-text-primary-foreground'
                                                    )}
                                                    asChild
                                                >
                                                    <Link
                                                        to={axios.getUri({
                                                            baseURL: '/dashboard',
                                                            params: {
                                                                my_folders: false,
                                                                tab: t('views.folders.tabs.all')
                                                            }
                                                        })}
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        {t('views.folders.all')}
                                                    </Link>
                                                </SidebarMenuButton>
                                            </SidebarMenuSubItem>
                                            <SidebarMenuSubItem>
                                                <SidebarMenuButton
                                                    variant={
                                                        location.pathname.startsWith('/dashboard/external')
                                                            ? 'outline'
                                                            : 'default'
                                                    }
                                                    className={cn(
                                                        location.pathname.startsWith('/dashboard/external') &&
                                                        'tw-bg-primary tw-text-primary-foreground ' +
                                                        'hover:!tw-bg-primary ' +
                                                        'hover:!tw-text-primary-foreground'
                                                    )}
                                                    asChild
                                                >
                                                    <Link
                                                        to={axios.getUri({
                                                            baseURL: '/dashboard/external',
                                                            params: {
                                                                tab: t('views.folders.tabs.external')
                                                            }
                                                        })}
                                                        onClick={() => setOpen(false)}
                                                    >
                                                        {t('views.folders.external')}
                                                    </Link>
                                                </SidebarMenuButton>
                                            </SidebarMenuSubItem>
                                        </SidebarMenuSub>
                                    </CollapsibleContent>
                                </SidebarMenuItem>
                            </Collapsible>
                            {isFeatureEnabled(Feature.PSJ) && <SidebarMenuItem>
                                <SidebarMenuButton
                                    variant={location.pathname.startsWith('/clients') ? 'outline' : 'default'}
                                    className={cn(
                                        location.pathname.startsWith('/clients') &&
                                        'tw-bg-primary tw-text-primary-foreground ' +
                                        'hover:!tw-bg-primary ' +
                                        'hover:!tw-text-primary-foreground'
                                    )}
                                    asChild
                                >
                                    <Link
                                        to="/clients"
                                        onClick={() => setOpen(false)}
                                    >
                                        <FontAwesomeIcon
                                            className="tw-mr-2"
                                            width="1em"
                                            icon={faUser}
                                        />
                                        {t('views.clients.title')}
                                    </Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>}
                            <SidebarMenuItem>
                                <SidebarMenuButton
                                    variant={location.pathname.startsWith('/forms') ? 'outline' : 'default'}
                                    className={cn(
                                        location.pathname.startsWith('/forms') &&
                                        'tw-bg-primary tw-text-primary-foreground ' +
                                        'hover:!tw-bg-primary ' +
                                        'hover:!tw-text-primary-foreground'
                                    )}
                                    asChild
                                >
                                    <Link
                                        to="/forms"
                                        onClick={() => setOpen(false)}
                                    >
                                        <FontAwesomeIcon
                                            className="tw-mr-2"
                                            width="1em"
                                            icon={faListCheck}
                                        />
                                        {t('views.forms.title')}
                                    </Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>
                            {isFeatureEnabled(Feature.PSJ) && <SidebarMenuItem>
                                <SidebarMenuButton
                                    variant={location.pathname.startsWith('/unassigned') ? 'outline' : 'default'}
                                    className={cn(
                                        location.pathname.startsWith('/unassigned') &&
                                        'tw-bg-primary tw-text-primary-foreground ' +
                                        'hover:!tw-bg-primary ' +
                                        'hover:!tw-text-primary-foreground'
                                    )}
                                    asChild
                                >
                                    <Link
                                        to="/unassigned"
                                        onClick={() => setOpen(false)}
                                    >
                                        <FontAwesomeIcon
                                            className="tw-mr-2"
                                            width="1em"
                                            icon={faNoteSticky}
                                        />
                                        {t('views.notes.title')}
                                    </Link>
                                </SidebarMenuButton>
                            </SidebarMenuItem>}
                            {views.length > 0 && <hr className="tw-my-3"/>}
                            {views.map(v => (
                                <SidebarMenuItem key={v._id}>
                                    <SidebarMenuButton
                                        variant={location.pathname === `/view/${v._id}` ? 'outline' : 'default'}
                                        className={cn(
                                            location.pathname === `/view/${v._id}` &&
                                            'tw-bg-primary tw-text-primary-foreground ' +
                                            'hover:!tw-bg-primary ' +
                                            'hover:!tw-text-primary-foreground'
                                        )}
                                        asChild
                                    >
                                        <Link
                                            to={buildViewUrl(v)}
                                            onClick={() => setOpen(false)}
                                        >
                                            {getViewIcon(v)}
                                            <span>{to(v.title)}</span>
                                        </Link>
                                    </SidebarMenuButton>
                                </SidebarMenuItem>
                            ))}

                        </SidebarMenu>
                    </SidebarContent>
                </DismissableLayer>

                <SidebarFooter className="tw-h-[44px]" />
                <SidebarRail/>
            </Sidebar>
            <main className={cn(
                'tw-flex-1 tw-flex tw-flex-col !tw-pt-5'
            )}>
                <div className="tw-flex tw-flex-wrap tw-gap-4 tw-px-5 tw-items-center print:tw-hidden">
                    <Button
                        variant="secondary" size="lg"
                        className={cn(
                            'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                            '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                        )}
                        onClick={toggleSidebar}
                    >
                        <PlusIcon className="tw-size-4 tw-mr-2" />
                        {t('views.open')}
                    </Button>
                    {tabs.map((t) => (
                        <PSJTab key={t.url} {...t} onDelete={() => onDeleteTab(t.url)} />
                    ))}
                    <CloseAllTabs tabs={tabs} closeAllTabs={closeAllTabs} />
                    <HistoryMenuButton />
                </div>
                <div className="tw-flex-1 tw-flex tw-flex-col">
                    <Outlet />
                </div>
            </main>
        </>
    );
}

interface CloseAllTabsProps {
    tabs: Tab[];
    closeAllTabs: () => void;
}

const CloseAllTabs: React.FC<CloseAllTabsProps> = ({ tabs, closeAllTabs }) => {
    const { to } = useTranslation();
    return (
        <Tooltip>
            <TooltipTrigger asChild>
                <Button
                    disabled={tabs.length <= 1}
                    onClick={closeAllTabs} variant="secondary" size="icon" className={cn(
                        'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                        '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                    )}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'grey' }} />
                </Button>
            </TooltipTrigger>
            <TooltipContent>
                {to({ en: 'Close all tabs', fr: 'Fermer tous les onglets' })}
            </TooltipContent>
        </Tooltip>
    );
};

function HistoryMenuButton() {
    const { to } = useTranslation();
    const { history } = useTabStore((state) => ({
        history: state.history
    }));
    return (
        <DropdownMenu>
            <Tooltip>
                <DropdownMenuTrigger asChild>
                    <TooltipTrigger asChild>
                        <Button
                            disabled={history.length === 0}
                            variant="secondary" size="icon" className={cn(
                                'tw-border-2 tw-border-dashed !tw-border-slate-300 !tw-rounded-full',
                                '!tw-px-4 hover:tw-bg-slate-200 tw-cursor-pointer'
                            )}>
                            <FontAwesomeIcon icon={faHistory} style={{ color: 'grey' }}/>
                        </Button>
                    </TooltipTrigger>
                </DropdownMenuTrigger>
                <TooltipContent>
                    {to({ en: 'Recently closed', fr: 'Récemment supprimé' })}
                </TooltipContent>
            </Tooltip>
            <DropdownMenuContent side="bottom" align='start' >
                {history.map((t) => {
                    const icon = iconMap[t.type];
                    return <DropdownMenuItem key={t.url} asChild>
                        <Link to={t.url}>
                            <FontAwesomeIcon icon={icon} className="tw-text-muted-foreground tw-mr-2" width="1em"/>
                            {to(t.label)}
                        </Link>
                    </DropdownMenuItem>;
                })}
            </DropdownMenuContent>
        </DropdownMenu>
    );
}
