import React, { useMemo } from 'react';
import { type ControllerProps, useFormContext, type UseFormReturn } from 'react-hook-form';
import { Combobox } from '@/components/ui/combobox';
import { InputNumber } from '@/components/ui/input-number';
import { DatePicker } from '@/components/ui/date-picker';
import { FormField } from '@/components/ui/form';
import { type ViewField } from '@/types/api/views';
import { Col, Row } from '@/components/ui/row';
import { useTranslation } from '@/composables/translation';
import { Input } from '@/components/ui/input';
import { PartySelect } from '@/components/psj/party/PartySelect';
import { getViewFieldType } from '@/composables/views';
import { type User, type UserGroup } from '@/types/api/user';
import { AssignSelect } from '@/components/psj/assign/AssignSelect';

interface ViewFilterProps extends React.ComponentProps<ControllerProps['render']> {
    form: UseFormReturn;
    users: User[];
    groups: UserGroup[];
}

function ViewFilterText({ form, field, users, groups }: ViewFilterProps) {
    const viewField = form.watch(`${field.name}.field`) as ViewField | null;
    const operatorName = `${field.name}.operator`;
    const operator = form.watch(operatorName) as string | null;
    const valueName = `${field.name}.value`;
    const value = form.watch(valueName) as string | null;
    const options = [
        {
            label: 'is',
            value: '$eq'
        },
        {
            label: 'contains',
            value: '$regex'
        }
    ];
    const isPartySelect = viewField?.description?.autofill_with === 'case_parties._id';
    const isAssignSelect = viewField?.description?.autofill_with === 'assign._id';
    return (
        <>
            <Col col={3} className="!tw-px-1">
                <Combobox
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    value={operator}
                    onChange={(val) => {
                        form.setValue(operatorName, val);
                        if (val !== operator) {
                            form.setValue(valueName, null);
                        }
                    }}
                />
            </Col>
            {operator &&
                <Col col={4}>
                    {isPartySelect
                        ? <FormField
                            name={valueName}
                            render={PartySelect}
                        />
                        : isAssignSelect
                            ? <FormField
                                name={valueName}
                                render={(context) => AssignSelect({ ...context, users, groups })}
                            />
                            : <Input
                                value={value ?? ''}
                                onChange={(e) => form.setValue(valueName, e.target.value)}
                            />
                    }
                </Col>
            }
        </>
    );
}

function ViewFilterDate({ form, field }: ViewFilterProps) {
    const operatorName = `${field.name}.operator`;
    const operator = form.watch(operatorName) as string | null;
    const valueName = `${field.name}.value`;
    const value = form.watch(valueName);
    const durationUnitName = `${field.name}.unit`;
    const durationUnit = form.watch(durationUnitName) as string | null;
    const options = [
        {
            label: 'before',
            value: '$lt'
        },
        {
            label: 'after',
            value: '$gt'
        },
        {
            label: 'is',
            value: '$eq'
        },
        {
            label: 'is not',
            value: '$ne'
        },
        {
            label: 'within the last',
            value: '$last'
        },
        {
            label: 'within the next',
            value: '$next'
        }
    ];
    const isWithin = ['$next', '$last'].includes(operator ?? '');
    return (
        <>
            <Col col={3} className="!tw-px-1">
                <Combobox
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    value={operator}
                    onChange={(val) => {
                        form.setValue(operatorName, val);
                        if (val !== operator) {
                            form.setValue(valueName, null);
                        }
                    }}
                />
            </Col>
            {!isWithin && operator &&
                <Col col={4} className="!tw-px-1">
                    <DatePicker
                        value={value}
                        onChange={(val) => form.setValue(valueName, val)}
                    />
                </Col>
            }
            {isWithin && operator && <>
                <Col col={2} className="!tw-px-1">
                    <InputNumber
                        value={value}
                        onChange={(val) => form.setValue(valueName, val)}
                    />
                </Col>
                <Col col={3} className="!tw-px-1">
                    <Combobox
                        options={['days', 'months', 'weeks', 'years']}
                        value={durationUnit}
                        onChange={(val) => form.setValue(durationUnitName, val)}
                    />
                </Col>
            </>}
        </>
    );
}

function ViewFilterNumber({ form, field }: ViewFilterProps) {
    const operatorName = `${field.name}.operator`;
    const operator = form.watch(operatorName) as string | null;
    const valueName = `${field.name}.value`;
    const value = form.watch(valueName) as number;
    const options = [
        {
            label: 'equal to',
            value: '$eq'
        },
        {
            label: 'not equal to',
            value: '$ne'
        },
        {
            label: 'less than',
            value: '$lt'
        },
        {
            label: 'greater than',
            value: '$gt'
        },
        {
            label: 'less than or equal to',
            value: '$lte'
        },
        {
            label: 'greater than or equal to',
            value: '$gte'
        }
    ];
    return (
        <>
            <Col col={3} className="!tw-px-1">
                <Combobox
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    value={operator}
                    onChange={(val) => {
                        form.setValue(operatorName, val);
                        if (val !== operator) {
                            form.setValue(valueName, null);
                        }
                    }}
                />
            </Col>
            {operator &&
                <Col col={3} className="!tw-px-1">
                    <InputNumber
                        value={value ?? null}
                        onChange={(val) => form.setValue(valueName, val)}
                    />
                </Col>
            }
        </>
    );
}

function ViewFilterBoolean({ form, field }: ViewFilterProps) {
    const operatorName = `${field.name}.operator`;
    const operator = form.watch(operatorName) as string | null;
    const valueName = `${field.name}.value`;
    const value = form.watch(valueName) as boolean | null;
    const options = [
        {
            label: 'is',
            value: '$eq'
        },
        {
            label: 'is not',
            value: '$ne'
        }
    ];
    return (
        <>
            <Col col={3} className="!tw-px-1">
                <Combobox
                    getOptionValue={(option) => option.value}
                    getOptionLabel={(option) => option.label}
                    options={options}
                    value={operator}
                    onChange={(val) => {
                        form.setValue(operatorName, val);
                        if (val !== operator) {
                            form.setValue(valueName, null);
                        }
                    }}
                />
            </Col>
            {operator &&
                <Col col={3} className="!tw-px-1">
                    <Combobox
                        getOptionLabel={(option) => String(option)}
                        options={[true, false]}
                        value={value}
                        onChange={(val) => form.setValue(valueName, val)}
                    />
                </Col>
            }
        </>
    );
}

interface Props {
    name: string;
    context?: {
        fields: ViewField[];
        users: User[];
        groups: UserGroup[];
    };
}

export function ViewFilter(props: Props) {
    const form = useFormContext();
    const resourceName = form.watch('definition.resource_name');
    const fields = useMemo(
        () => (props.context?.fields ?? [])
            .filter(vf => vf.resource_name === resourceName),
        [props.context?.fields, resourceName]
    );
    const components: Record<string, React.FC<ViewFilterProps> | undefined> = {
        str: ViewFilterText,
        datetime: ViewFilterDate,
        int: ViewFilterNumber,
        double: ViewFilterNumber,
        bool: ViewFilterBoolean,
        dict: undefined,
        list: undefined
    };
    return (<FormField
        name={props.name}
        render={(context) => {
            const { to } = useTranslation();
            const form = useFormContext();
            const field = form.watch(`${context.field.name}.field`) as ViewField | null;
            const fieldType = field ? getViewFieldType(field) : undefined;
            return (
                <Row className="tw-flex !-tw-mx-1">
                    <Col col={4} className="!tw-px-1">
                        <Combobox
                            options={fields}
                            getOptionLabel={(option) => to(option.title)}
                            value={field}
                            onChange={(value) => form.setValue(`${context.field.name}.field`, value)}
                        />
                    </Col>
                    {field && fieldType && components[fieldType]?.({
                        ...context,
                        form,
                        users: props.context?.users ?? [],
                        groups: props.context?.groups ?? []
                    })}
                </Row>
            );
        }}
    />);
}
