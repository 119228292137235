import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCalendar,
    faEraser, faEye, faList, faPaperclip,
    faPaperPlane, faSave, faTrash,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { type Language, useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { toast } from 'react-toastify';
import { postAddNote, postUploadFile } from '@/composables/api';
import { useFolderStore } from '@/store/folders';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import { Combobox } from '@/components/ui/combobox';
import { useUserStore } from '@/store/user';
import { useError } from '@/composables/error';
import { type PostAddNoteDto } from '@/types/api/note';
import { getInputFileList, isEmail } from '@/composables/utils';
import { TextFieldEdit } from '@/components/TextFieldEdit';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@/components/ui/dialog';
import { type Macro } from '@/types/api/macro';
import { type NextStepInstance } from '@/types/api/process';
import { Liquid } from 'liquidjs';
import axios from 'axios';
import { type File } from '@/types/api/files';
import { DateTime } from 'luxon';
import { type Assign, useAssignment } from '@/composables/assignment';
import { useOverrideConfig } from '@/composables/override';
import { DropZoneWrapper } from '@/components/utils/DropZoneWrapper';
import { FilePreview } from '@/components/neoform/helper/FilePreview';
import { type CaseStatus } from '@/types/folder';
import { ConfirmDialog } from '@/components/ConfirmDialog';
import { toolbarOptions } from '@/components/utils/toolbar-options';
import { ButtonAsync } from '@/components/ui/button-async';
import { STATUS_INFO } from '@/components/psj/StatusChip';
import { MenuButton } from '@/components/MenuButton';
import { ExistingFileAttachmentDialog } from './ExistingFileAttachmentDialog';
import { Checkbox } from '@/components/ui/checkbox';
import { useForm } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Col, Row } from '@/components/ui/row';
import { SectionLabel } from '@/components/ui/section-label';
import { Input } from '@/components/ui/input';
import { useValidation } from '@/composables/validation';
import { RequiredMarker } from '@/components/ui/required-marker';
import { OptionalMarker } from '@/components/ui/optional-marker';
import { ButtonSubmit } from '@/components/ui/button-submit';
import _ from 'lodash';
import { Feature, useFeatures } from '@/composables/features';

type PreviewFile = {
    mimeType: string;
    url?: string;
    name: string;
  } | null;

const engine = new Liquid();

interface FormValues {
    quick_note: boolean;
    type: 'private' | 'public' | 'email';
    status: CaseStatus | null;
    macro: Macro | null;
    nextstep: NextStepInstance | null;
    assign: Assign | null;
    note: string;
    attachments: File[];
    notifications: {
        email: boolean;
        sms: boolean;
        subject: string;
        to: string[] | null;
        cc: string[] | null;
    };
}

export function NoteEdit() {
    const folder = useContext(FolderContext);
    const { handleNetworkError } = useError();
    const { to, ct, t } = useTranslation('psj.notes');
    const { t: t_status } = useTranslation('folder.status');
    const { lang, user } = useUserStore((state) => ({
        lang: state.lang,
        user: state.user
    }));
    const override = useOverrideConfig();
    const { required } = useValidation();
    const { isFeatureEnabled } = useFeatures();

    const fileInput = useRef<HTMLInputElement | null>(null);
    const form = useForm<FormValues>({
        defaultValues: {
            quick_note: false,
            type: override?.is_customer_portal ? 'public' : 'private',
            status: null,
            macro: null,
            nextstep: null,
            assign: null,
            note: '',
            attachments: [],
            notifications: {}
        }
    });
    const [macroLang, setMacroLang] = useState<Language>(lang ?? 'fr');
    const [previewFile, setPreviewFile] = useState<PreviewFile>(null);
    const [openBifurcation, setOpenBifurcation] = useState(false);
    const [openExistingFile, setOpenExistingFile] = useState(false);
    const attachments = form.watch('attachments');
    const note = form.watch('note');
    const type = form.watch('type');
    const email = form.watch('notifications.email');
    const sms = form.watch('notifications.sms');
    const isPublic = type === 'public' || type === 'email';
    const isGlobalNextstep = folder?.case?.nextstep && typeof folder.case.nextstep.index !== 'number';
    const globalService = isGlobalNextstep
        ? JSON.stringify({
            _id: folder?.case?.nextstep?._id,
            title: folder?.case?.nextstep?.title,
            type: folder?.case?.nextstep?.definition?.calendar?.type,
            category: folder?.case?.nextstep?.definition?.calendar?.category,
            duration: folder?.case?.nextstep?.definition?.calendar?.duration,
            max_book: folder?.case?.nextstep?.definition?.calendar?.max_book
        })
        : undefined;
    const calendarUrl = axios.getUri({
        baseURL: `/calendar/book/${folder?.folder_id ?? ''}`,
        params: {
            ticket_id: folder?.folder_id,
            case_id: folder?.case?._id,
            domain_id: folder?.case?.product?._id,
            service_id: folder?.case?.nextstep?._id,
            groups: folder?.case?.groups.map(g => g._id),
            global_service: globalService
        }
    });

    const macroOptions = useMemo(
        () => [...(folder?.macros ?? [])],
        [folder?.macros, macroLang]
    );
    const emailOptions = useMemo(
        () => folder?.parties
            .map(p => [email ? p.email : undefined, sms ? p.phone : undefined])
            .flat().filter(Boolean) as string[] ?? [],
        [folder?.parties, email, sms]
    );
    const emailGroupBy = (opt: string) => {
        return opt.includes('@') ? ct('email') : ct('phone');
    };

    const {
        options: assignOptions,
        groupBy: assignGroupBy
    } = useAssignment({
        users: folder?.users ?? [],
        groups: folder?.groups ?? []
    });

    const {
        getNote,
        setStorageNote,
        removeNote
    } = useFolderStore((state) => ({
        getNote: state.getNote,
        setStorageNote: state.setNote,
        removeNote: state.removeNote
    }));

    const isPublicOptions = [
        {
            label: t('private-note'),
            value: 'private'
        },
        {
            label: t('public-note'),
            value: 'email'
        }
    ];

    useEffect(() => {
        handleGetLocalStorageNote();
    }, []);

    useEffect(() => {
        if (folder?.parties) {
            form.setValue(
                'notifications.to',
                folder.parties.filter(p => p.type === 'client').map(p => p.email)
            );
        }
    }, [folder?.parties]);

    useEffect(() => {
        const noteTimeout = setTimeout(() => {
            handleSetStorageNote();
        }, 2000);
        return () => clearTimeout(noteTimeout);
    }, [note]);

    useEffect(() => {
        if (folder?.case?.future_nextstep) {
            handleSelectNextStep(folder.case.future_nextstep, true);
        }
    }, [folder?.case?.future_nextstep]);

    useEffect(() => {
        const macroId = folder?.case?.nextstep?.definition?.folder?.open_macro;
        const macro = folder?.macros?.find(m => m._id === macroId);
        if (macro) {
            handleMacroSelectChange(macro);
        }
    }, [folder?.case, folder?.macros]);

    useEffect(() => {
        const loggedUser = folder?.users?.find(u => u.id.$oid === user?.user_id);
        if (loggedUser) {
            form.setValue('assign', {
                type: 'user',
                value: loggedUser.id.$oid,
                label: `${loggedUser.firstname} ${loggedUser.lastname}`
            });
        }
    }, [folder?.users, user?.user_id]);

    useEffect(() => {
        if (!email) {
            const recipients = form.getValues('notifications.to');
            if (recipients) {
                form.setValue('notifications.to', recipients.filter((value) => !isEmail(value)));
            }
        }
    }, [email]);

    useEffect(() => {
        if (!sms) {
            const recipients = form.getValues('notifications.to');
            if (recipients) {
                form.setValue('notifications.to', recipients.filter(isEmail));
            }
        }
    }, [sms]);

    function handleReset() {
        form.setValue('note', '');
        form.setValue('attachments', []);
        form.setValue('macro', null);
        handleSelectNextStep(null);
        form.setValue('assign', null);
        form.setValue('type', override?.is_customer_portal ? 'public' : 'private');
    }

    function handlePreSubmit({ nextstep }: FormValues) {
        const isNextStepTarget = folder?.case?.future_nextstep?._id === nextstep?._id;
        const isAllowedBifurcation = nextstep?.definition?.folder?.bifurcation === true;
        if (isNextStepTarget || (!isNextStepTarget && isAllowedBifurcation)) {
            return handleSubmit(form.getValues());
        } else {
            setOpenBifurcation(true);
        }
    }

    function handleSubmit({ quick_note, macro, assign, nextstep, status, notifications, attachments }: FormValues) {
        const [to_email, to_phone] = _.partition(notifications.to ?? [], isEmail);
        const obj: PostAddNoteDto = {
            type: 'update',
            lang,
            is_public: isPublic,
            active: true,
            attachments,
            ...macro?.actions,
            origin: {
                source: override?.is_customer_portal ? 'portal' : 'neodesk'
            },
            action: {
                id: macro ? macro._id : (isPublic ? '02' : '01'),
                type: macro ? 'ma' : 'ca'
            },
            case: {
                _id: folder?.case?._id,
                ...(assign && !quick_note && {
                    assign: {
                        _id: assign.value,
                        type: assign.type,
                        name: assign.label
                    }
                }),
                ...(nextstep && !quick_note && {
                    nextstep
                }),
                ...(macro && { macro }),
                ...macro?.actions?.case,
                ...(!quick_note && nextstep?.definition?.case),
                ...(status && !quick_note && { status }),
                ...(isPublic && { status: 'open' })
            },
            note,
            ...(type === 'email' && notifications.email && {
                emails: {
                    subject: notifications.subject,
                    to: to_email.map((email) => ({ fullname: '', email })) ?? [],
                    cc: notifications.cc?.map((email) => ({ fullname: '', email })) ?? []
                }
            }),
            ...(type === 'email' && notifications.sms && {
                sms: {
                    to: to_phone
                }
            })
        };
        if (obj.note === '<p><br></p>') {
            obj.note = '';
        }
        if (nextstep && nextstep._id !== folder?.case?.nextstep?._id) {
            obj.note += `<p>${to({
                en: 'Next step changed',
                fr: 'Changement d\'étape'
            })} : <b>${to(folder?.case?.nextstep?.title)} >>> ${to(nextstep.title)}</b></p>`;
        }
        if (assign && assign.value !== folder?.case?.assign?._id) {
            obj.note += `<p>${to({
                en: 'Assignment changed',
                fr: 'Changement d\'assignation'
            })} : <b>${to(folder?.case?.assign?.name)} >>> ${assign.label}</b></p>`;
        }
        if (status && status !== folder?.case?.status) {
            obj.note += `<p>${to({
                en: 'Status changed',
                fr: 'Changement de statut'
            })} : <b>${
                t_status(STATUS_INFO[folder?.case?.status ?? 'open'].title)
            } >>> ${
                t_status(STATUS_INFO[status].title)
            }</b></p>`;
        }
        return postAddNote(obj)
            .then((res) => {
                const { data } = res;
                folder?.setNotes([data.note, ...folder.notes]);
                const history = folder?.case?.history ?? {
                    nextstep: [],
                    assign: [],
                    status: []
                };
                Object.keys(history)
                    .forEach((key) => {
                        if ((data?.note?.case as any)?.[key]) {
                            ((history as any)[key]).push({
                                data: (data.note.case as any)[key],
                                updated_at: DateTime.now().toISO()
                            });
                        }
                    });
                // @ts-expect-error 2345
                folder?.setCaseInfo({
                    ...folder.case,
                    ...data.note.case,
                    history
                });
                handleReset();
                toast(ct('messages.success'), { type: 'success' });
                removeNote(folder?.folder_id ?? '');
                folder?.getCase?.();
            })
            .catch(handleNetworkError);
    }

    function handleGetLocalStorageNote() {
        const note = getNote(folder?.folder_id ?? '');
        if (note) {
            form.setValue('note', note);
        }
    }

    function handleSetStorageNote() {
        if (note && folder?.folder_id) {
            setStorageNote(folder?.folder_id, note);
        }
    }

    function handleSelectNextStep(ns: NextStepInstance | null, skipMacroSelection = false) {
        form.setValue('status', ns?.definition?.folder?.status ?? null);
        if (!skipMacroSelection && ns?.definition?.folder?.macro) {
            handleMacroSelectChange(folder?.macros?.find(m => m._id === ns.definition.folder.macro) ?? null);
        }
        if (ns?.definition?.folder?.assign) {
            form.setValue('assign', assignOptions.find(a => a.value === ns.definition.folder.assign) ?? null);
        }
        form.setValue('nextstep', ns);
    }

    function handleMacroSelectChange(macro: Macro | null) {
        form.setValue('macro', macro);
        form.setValue('notifications.email', !!macro?.actions?.is_public && !!macro?.definition?.email);
        form.setValue('notifications.sms', !!macro?.actions?.is_public && !!macro?.definition?.sms);
        const template = to(macro?.content_template, '', macroLang);
        if (macro?.actions?.case?.status) {
            form.setValue('status', macro.actions.case.status);
        }
        if (macro && template) {
            const note = engine.parseAndRenderSync(
                template,
                {
                    ...folder?.case,
                    input: folder?.input.data
                }
            );
            form.setValue('note', note);
            form.setValue('type', macro.actions?.is_public ? 'email' : 'private');
        } else if (!macro) {
            form.setValue('note', '');
        }
    }

    function handleClearQuill() {
        form.setValue('note', '');
        removeNote(folder?.folder_id ?? '');
    }

    function handleUploadFiles(e: React.ChangeEvent<HTMLInputElement>) {
        const files = getInputFileList(e.target);
        return Promise.all(
            files.map((f) =>
                postUploadFile(f, {
                    folder_id: folder?.folder_id,
                    case_id: folder?.case?._id,
                    target: 'NEODESK_files',
                    category: 'other',
                    email: user?.email
                })
            )
        )
            .then((res) => {
                const attachments = form.getValues('attachments');
                const newAttachments = res.map((r) => r.data);
                form.setValue('attachments', [...attachments, ...newAttachments]);
            })
            .catch(handleNetworkError)
            .finally(() => {
                if (fileInput.current) {
                    fileInput.current.value = '';
                }
            });
    }

    function handleRemoveAttachment(id: string): void {
        const newAttachments = form.getValues('attachments').filter(f => f._id !== id);
        form.setValue('attachments', newAttachments);
    }

    return (
        <DropZoneWrapper handleFileUpload={handleUploadFiles} hoverBorder={false}>
            <Card>
                <CardContent className="!tw-p-3">
                    <Form {...form}>
                        <form onSubmit={form.handleSubmit(handlePreSubmit)}>
                            {!override?.is_customer_portal && <div className={cn(
                                'tw-flex tw-flex-wrap tw-justify-between',
                                '!tw-items-end tw-gap-x-15 tw-gap-y-3 tw-mb-2'
                            )}>
                                <FormField
                                    name="type"
                                    render={({ field }) =>
                                        <Tabs
                                            value={field.value}
                                            onValueChange={field.onChange}
                                        >
                                            <TabsList>
                                                {isPublicOptions.map(({
                                                    value,
                                                    label
                                                }) => (
                                                    <TabsTrigger key={value} value={value}>
                                                        {label}
                                                    </TabsTrigger>
                                                ))}
                                            </TabsList>
                                        </Tabs>
                                    }
                                />
                                {macroOptions.length > 0 &&
                                    <FormField
                                        name="macro"
                                        render={({ field: { ref, ...field } }) =>
                                            <div className="tw-flex tw-items-end tw-gap-2">
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Button
                                                            className={cn(
                                                                'tw-text-primary hover:tw-text-primary',
                                                                '!tw-px-3 tw-uppercase'
                                                            )}
                                                            variant="outline"
                                                            onClick={() => setMacroLang(macroLang === 'fr'
                                                                ? 'en'
                                                                : 'fr')}
                                                        >
                                                            {macroLang}
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        {t('macros.tooltip')}
                                                    </TooltipContent>
                                                </Tooltip>
                                                <div className="tw-flex tw-flex-col tw-space-y-1 tw-w-56">
                                                    <FormLabel>{t('macros.label')}</FormLabel>
                                                    <FormControl>
                                                        <Combobox<Macro>
                                                            options={macroOptions}
                                                            getOptionLabel={(opt) => to(opt.title, '', macroLang)}
                                                            getValueLabel={(value) => to(value.title, '', macroLang)}
                                                            clearable
                                                            {...field}
                                                            innerRef={ref}
                                                            onChange={handleMacroSelectChange}
                                                        />
                                                    </FormControl>
                                                </div>
                                            </div>
                                        }
                                    />
                                }
                                <Dialog>
                                    <DialogTrigger asChild>
                                        <Button
                                            className="tw-text-primary hover:tw-text-primary"
                                            variant="outline"
                                        >
                                            <FontAwesomeIcon className="tw-mr-2" icon={faCalendar}/>
                                            {t('calendar.tooltip')}
                                        </Button>
                                    </DialogTrigger>
                                    <DialogContent
                                        className="md:tw-max-w-5xl tw-h-[90%] !tw-flex tw-flex-col !tw-gap-0">
                                        <DialogHeader>
                                            <DialogTitle>{t('calendar.title')}</DialogTitle>
                                        </DialogHeader>
                                        <div className="tw-w-full tw-flex-1 tw-mt-4">
                                            <iframe
                                                className="tw-w-full tw-h-full"
                                                src={calendarUrl}
                                            />
                                        </div>
                                    </DialogContent>
                                </Dialog>
                            </div>}
                            <FormField
                                name="note"
                                rules={{
                                    validate: {
                                        charLimit: (value, formValues) => {
                                            const el = document.createElement('div');
                                            el.innerHTML = value;
                                            return !formValues.notifications.sms || el.innerText.length <= 1000 || to({
                                                en: 'Message too long (max 1000 chars) due to SMS limit',
                                                fr: 'Message trop long (max 1000 caractères) en raison de la limite SMS'
                                            });
                                        }
                                    }
                                }}
                                render={({ field, fieldState }) =>
                                    <div className="tw-relative">
                                        <ReactQuill
                                            className={cn(
                                                'tw-mb-1 contenteditable',
                                                isPublic && 'quill-public',
                                                fieldState.error && 'tw-border tw-border-destructive'
                                            )}
                                            modules={{ toolbar: toolbarOptions }}
                                            {...field}
                                        />
                                        <FormMessage />
                                        <div className="tw-flex !tw-absolute tw-top-[3px] tw-right-1">
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <MenuButton
                                                        tooltip={to({
                                                            en: 'Add attachment',
                                                            fr: 'Ajouter une pièce jointe'
                                                        })}
                                                        id="quill-attachment"
                                                        options={[
                                                            {
                                                                label: to({
                                                                    en: 'Existing file',
                                                                    fr: 'Fichier existant'
                                                                }),
                                                                onClick: () => setOpenExistingFile(true),
                                                                value: 'existing_file',
                                                                icon: faList
                                                            },
                                                            {
                                                                label: to({ en: 'New file', fr: 'Nouveau fichier' }),
                                                                onClick: () => fileInput.current?.click(),
                                                                value: 'new_file',
                                                                icon: faUpload
                                                            }
                                                        ]}
                                                        renderButton={(props) => (
                                                            <Button
                                                                {...props}
                                                                variant="ghost" size="icon"
                                                                className="!tw-rounded-full tw-text-primary tw-text-lg"
                                                            >
                                                                <FontAwesomeIcon icon={faPaperclip}/>
                                                            </Button>
                                                        )}
                                                    />
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {t('quill.add-file')}
                                                </TooltipContent>
                                            </Tooltip>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant="ghost" size="icon"
                                                        className="!tw-rounded-full tw-text-primary tw-text-lg"
                                                        onClick={handleClearQuill}
                                                    >
                                                        <FontAwesomeIcon icon={faEraser}/>
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    {t('quill.erase')}
                                                </TooltipContent>
                                            </Tooltip>
                                            {!override?.is_customer_portal &&
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <ButtonAsync
                                                            variant="ghost" size="icon"
                                                            className="!tw-rounded-full tw-text-primary tw-text-lg"
                                                            onClick={() => {
                                                                form.setValue('quick_note', true);
                                                                form.handleSubmit(handleSubmit)();
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faSave}/>
                                                        </ButtonAsync>
                                                    </TooltipTrigger>
                                                    <TooltipContent>
                                                        {t('quick-note.tooltip')}
                                                    </TooltipContent>
                                                </Tooltip>
                                            }
                                        </div>
                                    </div>
                                }
                            />
                            {isPublic &&
                                <Row className="tw-py-2">
                                    <Col col={2}>
                                        <SectionLabel>
                                            {to({
                                                en: 'Notify by',
                                                fr: 'Notifier par'
                                            })}
                                        </SectionLabel>
                                    </Col>
                                    <Col col={4} className="tw-self-end">
                                        <FormField
                                            name="notifications.email"
                                            render={({ field }) =>
                                                <FormItem>
                                                    <FormLabel className="tw-flex tw-gap-2">
                                                        <FormControl>
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value}
                                                                onCheckedChange={field.onChange}
                                                            />
                                                        </FormControl>
                                                        {ct('email')}
                                                    </FormLabel>
                                                </FormItem>
                                            }
                                        />
                                    </Col>
                                    {isFeatureEnabled(Feature.SMS) && <Col col={4} className="tw-self-end">
                                        <FormField
                                            name="notifications.sms"
                                            render={({ field }) =>
                                                <FormItem>
                                                    <FormLabel className="tw-flex tw-gap-2">
                                                        <FormControl>
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value}
                                                                onCheckedChange={field.onChange}
                                                            />
                                                        </FormControl>
                                                        {ct('sms')}
                                                    </FormLabel>
                                                </FormItem>
                                            }
                                        />
                                    </Col>}
                                </Row>}
                            {isPublic && (email || sms) && <div className="tw-flex tw-flex-col tw-gap-2 tw-pb-3">
                                {email && <FormField
                                    name="notifications.subject"
                                    rules={{ validate: { required } }}
                                    render={({ field, fieldState }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('email-dialog.fields.subject')}
                                                <RequiredMarker/>
                                            </FormLabel>
                                            <FormControl>
                                                <Input {...field} error={!!fieldState.error}/>
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />}
                                <FormField
                                    name="notifications.to"
                                    rules={{ validate: { required } }}
                                    render={({ field: { ref, ...field }, fieldState }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('email-dialog.fields.recipients')}
                                                <RequiredMarker/>
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox
                                                    options={emailOptions}
                                                    groupBy={emailGroupBy}
                                                    multiple
                                                    creatable
                                                    clearable
                                                    error={!!fieldState.error}
                                                    {...field}
                                                    innerRef={ref}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />
                                {email && <FormField
                                    name="notifications.cc"
                                    render={({ field: { ref, ...field }, fieldState }) =>
                                        <FormItem>
                                            <FormLabel>
                                                {t('email-dialog.fields.cc')}
                                                <OptionalMarker />
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox
                                                    id={field.name}
                                                    options={emailOptions}
                                                    multiple
                                                    creatable
                                                    clearable
                                                    {...field}
                                                    innerRef={ref}
                                                    error={!!fieldState.error}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    }
                                />}
                                <hr className="-tw-mx-3 tw-mt-3" />
                            </div>}
                            {attachments.length > 0 && <div className="tw-mt-2">
                                <h3 className="tw-text-primary tw-font-semibold">{t('attachments.title')}</h3>
                                <div className="tw-flex tw-flex-col tw-gap-2 tw-py-2">
                                    {attachments.map((f) =>
                                        <div key={f._id} className="tw-flex tw-items-center tw-gap-4">
                                            <TextFieldEdit
                                                value={f.name}
                                                onChange={(value) => {
                                                    f.name = value;
                                                    form.setValue('attachments', [...attachments]);
                                                }}
                                            />
                                            <div className="tw-ml-auto tw-flex">
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Button
                                                            variant="ghost" size="icon"
                                                            className="!tw-rounded-full tw-text-primary"
                                                            onClick={() => setPreviewFile({
                                                                url: f?.url,
                                                                mimeType: f?.content_type ?? '',
                                                                name: f?.name
                                                            })}
                                                        >
                                                            <FontAwesomeIcon icon={faEye}/>
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent>{t('attachments.preview')}</TooltipContent>
                                                </Tooltip>
                                                <Tooltip>
                                                    <TooltipTrigger asChild>
                                                        <Button
                                                            variant="ghost" size="icon"
                                                            className="!tw-rounded-full tw-text-danger"
                                                            onClick={() => handleRemoveAttachment(f._id)}
                                                        >
                                                            <FontAwesomeIcon icon={faTrash}/>
                                                        </Button>
                                                    </TooltipTrigger>
                                                    <TooltipContent>{t('attachments.remove')}</TooltipContent>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>}
                            <div className="tw-flex tw-gap-5 tw-justify-between tw-items-end">
                                {!override?.is_customer_portal &&
                                    <FormField
                                        name="nextstep"
                                        render={({ field: { ref, ...field } }) => <FormItem
                                            className="!tw-space-y-0.5 tw-w-2/5"
                                        >
                                            <FormLabel>
                                                {to({
                                                    en: 'Next step',
                                                    fr: 'L\'étape suivante'
                                                })}
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox<NextStepInstance>
                                                    options={folder?.next_steps ?? []}
                                                    getOptionLabel={opt =>
                                                        opt.index != null
                                                            ? `${opt.index + 1}. ${to(opt.title)}`
                                                            : to(opt.title)
                                                    }
                                                    getValueLabel={value =>
                                                        value.index != null
                                                            ? `${value.index + 1}. ${to(value.title)}`
                                                            : to(value.title)
                                                    }
                                                    groupBy={opt => opt.index != null ? 'Processus' : 'Global'}
                                                    clearable
                                                    {...field}
                                                    innerRef={ref}
                                                    onChange={(value) => handleSelectNextStep(value)}
                                                />
                                            </FormControl>

                                        </FormItem>}
                                    />
                                }
                                {!override?.is_customer_portal &&
                                    <FormField
                                        name="assign"
                                        render={({ field: { ref, ...field } }) => <FormItem
                                            className="!tw-space-y-0.5 tw-w-2/5"
                                        >
                                            <FormLabel>
                                                {t('assign.label')}
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox<Assign>
                                                    className="tw-min-w-sm"
                                                    options={assignOptions}
                                                    getOptionLabel={opt => opt.label}
                                                    getValueLabel={value => value.label}
                                                    groupBy={assignGroupBy}
                                                    clearable
                                                    {...field}
                                                    innerRef={ref}
                                                />
                                            </FormControl>
                                        </FormItem>}
                                    />
                                }
                                <ButtonSubmit
                                    color="primary"
                                    style={{ minWidth: '100px' }}
                                    loading={!!folder?.loading || form.formState.isSubmitting}
                                    className={cn('tw-w-1/5', override?.is_customer_portal && 'tw-ml-auto tw-mt-2')}
                                >
                                    <FontAwesomeIcon icon={faPaperPlane} className="tw-mr-2"/>
                                    {t('send')}
                                </ButtonSubmit>
                            </div>
                            <input
                                ref={fileInput}
                                type="file" className="tw-hidden"
                                multiple
                                onChange={handleUploadFiles}
                            />
                        </form>
                    </Form>
                </CardContent>
            </Card>
            <FilePreview
                file={previewFile ?? { name: '', url: '', mimeType: '' }}
                onOpenChange={() => setPreviewFile(null)}
                open={previewFile !== null}
            />
            <ConfirmDialog
                title={t('bifurcation.title')}
                message={t('bifurcation.message')}
                open={openBifurcation}
                onOpenChange={setOpenBifurcation}
                onConfirm={() => form.handleSubmit(handleSubmit)()}
            />
            <ExistingFileAttachmentDialog
                open={openExistingFile}
                setOpen={() => setOpenExistingFile(!openExistingFile)}
                setAttachments={(selectedFiles) => (form.setValue('attachments', [...attachments, ...selectedFiles]))}
            />
        </DropZoneWrapper>
    );
}
