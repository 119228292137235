import { CardCollapse } from '@/components/CardCollapse';
import { Button } from '@/components/ui/button';
import { CardContent, CardFooter } from '@/components/ui/card';
import { CrudDialog, CrudInputType, type CrudSchema, type CrudSelectInputOptions } from '@/components/ui/crud-table';
import { InputDuration } from '@/components/ui/input-duration';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { getActivities, getNotesList, getOrgUsers, postAddNote } from '@/composables/api';
import { useError } from '@/composables/error';
import { combine, useTranslation } from '@/composables/translation';
import { FolderContext } from '@/pages/psj/Folder';
import { useUserStore } from '@/store/user';
import { type Note } from '@/types/api/note';
import { type PricingLevel, type User } from '@/types/api/user';
import { DownloadIcon, DragHandleDots2Icon, OpenInNewWindowIcon, PlusIcon } from '@radix-ui/react-icons';
import { DateTime, Duration } from 'luxon';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Stopwatch } from '@/components/ui/stopwatch';
import { Spinner } from '@/components/ui/spinner';
import { Draggable, DraggableHandle } from '@/components/ui/draggable';
import { cn } from '@/lib/utils';
import { type Activity } from '@/types/api/activity';
import { ActivitySelect } from '@/components/psj/activity/ActivitySelect';
import { ButtonAsync } from '@/components/ui/button-async';
import { downloadTimelogReport } from '@/composables/timelog';
import axios from 'axios';
import { Link } from 'react-router-dom';

export const BILLING_TYPE_OPTIONS = [
    {
        value: 'package',
        label: 'Dossier au forfait'
    },
    {
        value: 'hourly',
        label: 'Dossier à l\'heure'
    }
];

export const BILLING_LEVEL_OPTIONS: PricingLevel[] = [
    {
        name: {
            fr: 'Tarification standard',
            en: 'Standard pricing'
        }
    }
];

interface TimeLogValues {
    activity: Activity;
    user: string;
    date: DateTime | string;
    duration: number;
    billing: {
        type: string;
        level?: PricingLevel;
        amount?: number;
    };
}

interface TimeLogEntryProps {
    label: string;
    date?: DateTime;
    value: string;
}

export function TimeLogEntry(props: TimeLogEntryProps) {
    return (
        <div className="tw-flex">
            <div>
                {props.date?.isValid && <>{props.date.toFormat('ff')}{' '}&mdash;{' '}</>}
                {props.label}
            </div>
            <div className="tw-flex-1 tw-text-right tw-text-primary tw-font-medium">
                {props.value}
            </div>
        </div>
    );
}

export function TimeLog() {
    const { ct, t, to } = useTranslation('psj.time-log');
    const { user, lang } = useUserStore(state => ({ lang: state.lang, user: state.user }));
    const { handleNetworkError } = useError();
    const folder = useContext(FolderContext);

    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState<User[]>([]);
    const [activities, setActivities] = useState<Activity[]>([]);
    const [timeLog, setTimeLog] = useState<Note[]>([]);
    const [value, setValue] = useState<Partial<TimeLogValues>>();
    const [loading, setLoading] = useState(false);
    const isLoading = loading || !!folder?.loading;
    const form = useForm<TimeLogValues>();
    const user_id = form.watch('user');

    const selectedUser = useMemo(
        () => users.find(u => u.id.$oid === user_id),
        [users, user_id]
    );

    const timelogUrl = useMemo(
        () => axios.getUri({
            baseURL: `/timelog/${folder?.case?._id ?? ''}`,
            params: {
                tab: `${t('title')} — ${folder?.folder_id ?? ''}`
            }
        }),
        [folder?.folder_id, folder?.case?._id]
    );

    const timeTotal = useMemo(
        () => timeLog.reduce((sum, note) => sum.plus({
            minutes: note.metadata.duration
        }), Duration.fromDurationLike({
            minutes: 0
        }).normalize()),
        [timeLog]
    );

    function getFormValue(opts: { time?: number } | undefined = undefined) {
        const activity = activities.find(a => a._id === folder?.case?.nextstep?._id);
        return {
            ...(activity && { activity }),
            date: DateTime.now(),
            duration: typeof opts?.time === 'number'
                ? Math.ceil(opts.time / 60)
                : NaN,
            user: user?.user_id ?? '',
            billing: {
                type: 'hourly',
                level: BILLING_LEVEL_OPTIONS[0]
            }
        };
    }

    function handleSubmit(values: TimeLogValues) {
        return postAddNote({
            type: 'time_log',
            lang,
            note: '',
            active: true,
            hidden: true,
            is_public: false,
            case: {
                _id: folder?.case?._id
            },
            metadata: values
        })
            .then((res) => {
                form.reset();
                toast.success(ct('messages.success'));
                setTimeLog((notes) => [...notes, res.data.note]);
                setOpen(false);
            })
            .catch(handleNetworkError);
    }

    function handleExportReport() {
        return downloadTimelogReport({
            to,
            case_id: folder?.case?._id ?? '',
            folder_id: folder?.folder_id ?? ''
        });
    }

    useEffect(() => {
        if (!folder?.case?._id) {
            return;
        }
        setLoading(true);
        Promise.all([
            getOrgUsers(),
            getActivities({
                case_id: folder?.case?._id,
                limit: 10000
            }),
            getNotesList({
                id: folder?.case?._id,
                note_type: 'time_log',
                order: 1,
                limit: 10000
            })
        ])
            .then(([users, activities, notes]) => {
                setUsers(users.data);
                setActivities(activities.data);
                setTimeLog(notes.data.notes);
            })
            .finally(() => setLoading(false));
    }, [folder?.case?._id]);

    const schema = useMemo<CrudSchema<TimeLogValues>>(
        () => [
            {
                id: 'activity',
                type: CrudInputType.CUSTOM,
                name: t('schema.type'),
                create: true,
                required: true,
                render: (context) => ActivitySelect({
                    ...context,
                    activities
                })
            },
            {
                id: 'user',
                type: CrudInputType.SELECT,
                name: t('schema.user'),
                required: true,
                clearable: true,
                defaultValue: user?.user_id,
                options: users,
                getOptionLabel: (opt) => `${opt.firstname} ${opt.lastname}`,
                getOptionValue: (opt) => opt.id.$oid,
                onChange: (form) => {
                    form.setValue('billing.level', BILLING_LEVEL_OPTIONS[0]);
                }
            } as CrudSelectInputOptions<TimeLogValues, User>,
            {
                id: 'date',
                type: CrudInputType.DATE,
                name: t('schema.date'),
                col: 6,
                defaultValue: DateTime.now(),
                required: true
            },
            {
                id: 'date',
                type: CrudInputType.TIME,
                name: t('schema.time'),
                col: 6,
                defaultValue: DateTime.now(),
                required: true
            },
            {
                id: 'duration',
                type: CrudInputType.CUSTOM,
                name: t('schema.duration'),
                required: true,
                defaultValue: NaN,
                render: ({ field, fieldState }) => (
                    <InputDuration
                        {...field as any}
                        error={!!fieldState.error}
                    />
                )
            },
            {
                id: 'billing.type',
                type: CrudInputType.SELECT,
                name: t('schema.billing-type'),
                options: BILLING_TYPE_OPTIONS,
                getOptionValue: (opt) => opt.value,
                getOptionLabel: (opt) => opt.label,
                defaultValue: 'hourly',
                required: true
            },
            {
                id: 'billing.level',
                type: CrudInputType.SELECT,
                name: t('schema.billing-level'),
                col: 6,
                options: [
                    ...BILLING_LEVEL_OPTIONS,
                    ...(selectedUser?.params?.pricing_levels ?? [])
                ],
                getOptionLabel: (opt) => to(opt.name),
                onChange: (form, value: PricingLevel) => {
                    if (value.amount != null) {
                        form.setValue('billing.amount', value.amount);
                    }
                },
                defaultValue: 'default',
                required: true,
                shouldDisplay: (form) => form.getValues('billing.type') === 'hourly'
            },
            {
                id: 'billing.amount',
                type: CrudInputType.NUMBER,
                name: t('schema.billing-amount'),
                col: 6,
                required: true,
                shouldDisplay: (form) => form.getValues('billing.type') === 'hourly',
                scale: 2,
                min: 0,
                max: 999999999999
            }
        ],
        [users, activities, selectedUser]
    );

    const TimeLogDialog = useMemo(() => CrudDialog<TimeLogValues, 'activity'>(), []);

    return (<>
        <Draggable
            className="!tw-fixed tw-z-50"
            defaultPosition={{ top: 8, right: 260 }}
        >
            <Stopwatch
                id={folder?.folder_id ?? ''}
                onStop={({ time }) => {
                    setOpen(true);
                    setValue(getFormValue({ time }));
                }}
                slots={{
                    title: <div className="tw-text-primary tw-text-sm tw-font-medium">
                        {folder?.folder_id}
                    </div>,
                    start: <DraggableHandle className={cn(
                        'tw-size-9 tw-cursor-grab -tw-mr-1',
                        'tw-flex tw-items-center tw-justify-center'
                    )}>
                        <DragHandleDots2Icon/>
                    </DraggableHandle>
                }}
            />
        </Draggable>
        <CardCollapse
            innerTitle
            title={t('title')}
            titleSlot={
                <TimeLogDialog
                    idKey="activity"
                    schema={schema}
                    form={form}
                    value={value as TimeLogValues}
                    open={open}
                    onOpenChange={setOpen}
                    onSubmit={handleSubmit}
                    translation={{
                        title: combine('psj.time-log.dialog.title')
                    }}
                >
                    <Tooltip>
                        <TooltipTrigger asChild>
                            <Button
                                className="tw-ml-auto !tw-rounded-full tw-text-primary hover:tw-text-primary"
                                variant="ghost" size="icon"
                                disabled={folder?.loading}
                                onClick={() => {
                                    setValue(getFormValue());
                                    setOpen(true);
                                }}
                            >
                                <PlusIcon className="tw-size-4" />
                            </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                            {t('tooltip')}
                        </TooltipContent>
                    </Tooltip>
                </TimeLogDialog>
            }
        >
            <CardContent className="!tw-p-4 !tw-pt-0">
                {isLoading &&
                    <div className="tw-flex tw-justify-center tw-pt-2 tw-pb-4">
                        <Spinner className="tw-text-primary"/>
                    </div>
                }
                {!isLoading && timeLog.length > 0 && <>
                    {timeLog.map((note) => {
                        const user = users.find(u => u.id.$oid === note.metadata.user);
                        if (!user) {
                            return null;
                        }
                        return (
                            <TimeLogEntry
                                key={note._id}
                                label={`${user.firstname} ${user.lastname}`}
                                date={DateTime.fromISO(note.metadata.date)}
                                value={
                                    Duration.fromDurationLike({ minutes: note.metadata.duration })
                                        .rescale()
                                        .toHuman({ unitDisplay: 'short' })
                                }
                            />
                        );
                    })}
                    <hr className="!tw-border-t-2 !tw-border-black tw-my-2" />
                    <TimeLogEntry
                        label="Total"
                        value={timeTotal.rescale().toHuman({ unitDisplay: 'short' })}
                    />
                </>}
                {!isLoading && timeLog.length <= 0 &&
                    <div className="tw-p-4 tw-text-center tw-text-sm tw-italic tw-text-muted-foreground">
                        {t('empty')}
                    </div>
                }
            </CardContent>
            {!isLoading && timeLog.length > 0 &&
                <CardFooter className="!tw-p-4 !tw-pt-0 tw-flex">
                    <Button className="!tw-p-0" variant="link">
                        <Link to={timelogUrl} className="tw-flex tw-items-center">
                            <OpenInNewWindowIcon className="tw-mr-2" />
                            {t('details')}
                        </Link>
                    </Button>
                    <ButtonAsync className="tw-ml-auto" size="sm" onClick={handleExportReport}>
                        <DownloadIcon className="tw-mr-2" />
                        Exporter
                    </ButtonAsync>
                </CardFooter>
            }
        </CardCollapse>
    </>);
}
