import React, { useEffect, useMemo } from 'react';
import { useTranslation } from '@/composables/translation';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { Card, CardTitle } from '@/components/ui/card';
import { cn } from '@/lib/utils';
import { Feature, useFeatures } from '@/composables/features';
import { Permission, usePermissions } from '@/composables/permissions';
import {
    Sidebar,
    SidebarContent,
    SidebarFooter,
    SidebarHeader,
    SidebarMenu,
    SidebarMenuButton,
    SidebarMenuItem,
    SidebarMenuSub,
    SidebarMenuSubItem,
    SidebarRail, SidebarTrigger,
    useSidebar
} from '@/components/ui/sidebar';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible';
import { ChevronRight } from 'lucide-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDiagramProject,
    faFileInvoiceDollar, faFont,
    faKey, faListCheck,
    faSitemap, faTable,
    faUser,
    faUsers,
    faUsersViewfinder
} from '@fortawesome/free-solid-svg-icons';

interface MenuGroup {
    name: string;
    children: MenuItem[];
}

interface MenuItem {
    label: string;
    to: string;
    fullWidth?: boolean;
    icon?: React.ReactNode;
}

export function SettingsLayout() {
    const location = useLocation();
    const { t, ct } = useTranslation('settings');
    const { isFeatureEnabled } = useFeatures();
    const { hasPermissions, isMaster } = usePermissions();
    const { open, setOpen } = useSidebar();

    const MENU_GROUPS: MenuGroup[] = [
        {
            name: t('nav.personal'),
            children: [
                {
                    label: t('account.link'),
                    to: '/settings/account',
                    icon: <FontAwesomeIcon icon={faUser} />,
                    fullWidth: false
                },
                {
                    label: t('password.link'),
                    to: '/settings/password',
                    icon: <FontAwesomeIcon icon={faKey} />,
                    fullWidth: false
                }
            ]
        },
        ...(hasPermissions(Permission.PSJ_ADMIN_PANEL)
            ? [{
                name: t('nav.organization'),
                children: [
                    {
                        label: t('users.link'),
                        to: '/settings/users',
                        icon: <FontAwesomeIcon icon={faUsers} />,
                        fullWidth: true
                    },
                    {
                        label: t('groups.link'),
                        to: '/settings/groups',
                        icon: <FontAwesomeIcon icon={faUsersViewfinder} />,
                        fullWidth: true
                    },
                    {
                        label: ct('org'),
                        to: '/settings/org',
                        icon: <FontAwesomeIcon icon={faSitemap} />,
                        fullWidth: false
                    },
                    {
                        label: t('forms.link'),
                        to: '/settings/forms',
                        fullWidth: true
                    }
                ]
            }]
            : []),
        ...(
            isFeatureEnabled(Feature.PSJ) && hasPermissions(Permission.PSJ_ADMIN_PANEL)
                ? [{
                    name: t('nav.psj'),
                    children: [
                        {
                            label: t('processes.link'),
                            to: '/settings/processes',
                            icon: <FontAwesomeIcon icon={faDiagramProject} />,
                            fullWidth: true
                        },
                        {
                            label: t('products.link'),
                            to: '/settings/products',
                            icon: <FontAwesomeIcon icon={faListCheck} />,
                            fullWidth: true
                        },
                        {
                            label: t('macros.link'),
                            to: '/settings/macros',
                            icon: <FontAwesomeIcon icon={faFont} />,
                            fullWidth: true
                        },
                        {
                            label: t('views.link'),
                            to: '/settings/views',
                            icon: <FontAwesomeIcon icon={faTable} />,
                            fullWidth: true
                        }
                    ]
                }]
                : []
        ),
        ...(hasPermissions(Permission.PSJ_ADMIN_PANEL)
            ? [
                {
                    name: t('nav.billing'),
                    children: [
                        {
                            label: t('invoices.link'),
                            to: '/settings/invoices',
                            icon: <FontAwesomeIcon icon={faFileInvoiceDollar} />,
                            fullWidth: true
                        }
                    ]
                }
            ]
            : []),
        ...(isMaster
            ? [{
                name: t('nav.admin'),
                children: [
                    {
                        label: t('organizations.link'),
                        to: '/settings/organizations',
                        fullWidth: true
                    }
                ]
            }]
            : [])
    ];

    const currentLink = useMemo(
        () => MENU_GROUPS.flatMap(g => g.children)
            .find((link) => link.to === location.pathname),
        [location.pathname]
    );

    useEffect(() => {
        setOpen(true);
    }, [location]);

    return (
        <>
            <Sidebar variant="nogap" className="tw-top-[60px] !tw-h-[calc(100svh_-_60px)] tw-border-r">
                <SidebarHeader className="tw-px-3 tw-pt-3">
                    <CardTitle className="!tw-text-base tw-flex tw-items-center">
                        <span>{t('title')}</span>
                        <SidebarTrigger className="tw-ml-auto" />
                    </CardTitle>
                </SidebarHeader>
                <SidebarContent className="tw-p-1">
                    {MENU_GROUPS.map((group) =>
                        <SidebarMenu key={group.name}>
                            <Collapsible defaultOpen className="tw-group/collapsible">
                                <SidebarMenuItem>
                                    <CollapsibleTrigger asChild>
                                        <SidebarMenuButton>
                                            <span>{group.name}</span>
                                            <ChevronRight className={cn(
                                                'tw-ml-auto tw-transition-transform',
                                                'group-data-[state=open]/collapsible:tw-rotate-90'
                                            )} />
                                        </SidebarMenuButton>
                                    </CollapsibleTrigger>
                                    <CollapsibleContent>
                                        <SidebarMenuSub>
                                            {group.children.map((item) =>
                                                <SidebarMenuSubItem key={item.to}>
                                                    <SidebarMenuButton
                                                        asChild
                                                        variant={
                                                            item.to === currentLink?.to
                                                                ? 'outline'
                                                                : 'default'
                                                        }
                                                        className={cn(
                                                            item.to === currentLink?.to &&
                                                            'tw-bg-primary tw-text-primary-foreground ' +
                                                                'hover:!tw-bg-primary ' +
                                                                'hover:!tw-text-primary-foreground'
                                                        )}
                                                    >
                                                        <Link to={item.to}>
                                                            {item.icon ?? null}
                                                            <span>{item.label}</span>
                                                        </Link>
                                                    </SidebarMenuButton>
                                                </SidebarMenuSubItem>
                                            )}
                                        </SidebarMenuSub>
                                    </CollapsibleContent>
                                </SidebarMenuItem>
                            </Collapsible>
                        </SidebarMenu>
                    )}
                </SidebarContent>
                <SidebarFooter className="tw-h-[44px]" />
                <SidebarRail/>
            </Sidebar>
            <div className={cn(
                'md:tw-pl-[var(--sidebar-width)] tw-w-full',
                !open && 'md:!tw-pl-0'
            )}>
                <div className={cn(
                    'tw-p-4 tw-flex tw-flex-col tw-items-center',
                    'sm:tw-mx-auto md:tw-mx-0',
                    !currentLink?.fullWidth && 'tw-max-w-screen-md'
                )}>
                    <Card className="tw-w-full">
                        <Outlet/>
                    </Card>
                </div>
            </div>
        </>
    );
}
