import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

interface State {
    times: Record<string, number | undefined>;
}

interface Actions {
    get: (id: string) => number | undefined;
    set: (id: string, time: number) => void;
    clear: (id: string) => void;
}

export const useTimeStore = create<State & Actions>()(
    devtools(
        persist(
            (set, get) => ({
                times: {},
                get: (id) => get().times[id],
                set: (id, time) => set((state) => ({
                    times: {
                        ...state.times,
                        [id]: time
                    }
                })),
                clear: (id) => set((state) => ({
                    times: {
                        ...state.times,
                        [id]: undefined
                    }
                }))
            }),
            {
                name: 'time'
            }
        )
    )
);
