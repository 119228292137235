import React, { useEffect, useMemo, useState } from 'react';
import {
    CrudDialog,
    CrudInputType,
    type CrudSchema,
    type CrudSelectInputOptions,
    type UpdateDialogProps
} from '@/components/ui/crud-table';
import { combine, type TranslationObject, useTranslation } from '@/composables/translation';
import { useLanguage } from '@/composables/language';
import { type NeoFormCreateDto } from '@/types/neoform';
import { type FormType } from '@/types/api/forms';
import { getForms } from '@/composables/api';
import { useUserStore } from '@/store/user';
import { BindProps } from '@/components/utils/BindProps';
import { type WithOptional } from '@/types/utils';

type CreateFormType = Partial<NeoFormCreateDto & { case_id: string }>;

interface Props extends WithOptional<UpdateDialogProps<CreateFormType, 'case_id'>, 'idKey' | 'schema'> {
}

export function CreateFormDialog(props: Props) {
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const { to, ct } = useTranslation();
    const { options } = useLanguage();
    const [forms, setForms] = useState<FormType[]>([]);
    const groups = useMemo(
        () => forms.reduce<Record<string, TranslationObject>>(
            (obj, form) => {
                if (form.category) {
                    obj[form.category.name] = form.category.label;
                }
                return obj;
            }, {}
        ),
        [forms]
    );
    useEffect(() => {
        getForms().then((res) => setForms(res.data));
    }, []);
    const schema = useMemo<CrudSchema<CreateFormType>>(() => [
        {
            id: 'ticket_id',
            type: CrudInputType.TEXT,
            name: ct('folder'),
            required: true,
            col: 6,
            columnDef: {
                id: 'folder_id',
                accessorKey: 'folder_id',
                header: ct('folder')
            }
        },
        {
            id: 'lang',
            type: CrudInputType.SELECT,
            defaultValue: lang,
            name: ct('language'),
            required: true,
            col: 6,
            options,
            getOptionValue: (opt) => opt.value,
            getOptionLabel: (opt) => opt.label
        } as CrudSelectInputOptions<CreateFormType, typeof options[number]>,
        {
            id: 'form_id',
            type: CrudInputType.SELECT,
            name: ct('form'),
            required: true,
            options: forms,
            getOptionValue: (opt) => opt.id,
            getOptionLabel: (opt) => to(opt.title),
            groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
        } as CrudSelectInputOptions<CreateFormType, FormType>
    ], [forms, groups]);
    const DialogComponent = useMemo(
        () => BindProps(CrudDialog<CreateFormType, 'case_id'>(), { schema, idKey: 'case_id' }),
        [schema]
    );
    return (
        <DialogComponent
            {...props}
            translation={{
                title: combine('common.create'),
                submit: combine('common.create')
            }}
        />
    );
}
