import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { type CrudApi, CrudDialog, CrudInputType, type CrudSchema, CrudTable } from '@/components/ui/crud-table';
import { type InvoiceFeature, type Org, roleOptions, type UserInfos } from '@/types/api/orgs';
import {
    getForms,
    getFormsMaster,
    getOrganizations,
    postCreateOrg,
    postUpdateOrg,
    postUpdateOrgForms
} from '@/composables/api';
import { combine, type TranslationObject, useTranslation } from '@/composables/translation';
import { clone } from '@/composables/utils';
import { useLanguage } from '@/composables/language';
import { useUserStore } from '@/store/user';
import { DateTime } from 'luxon';
import { BillingNeoform } from '@/components/billing/features/BillingNeoform';
import { BillingNeodesk } from '@/components/billing/features/BillingNeodesk';
import { Feature } from '@/composables/features';
import { Label } from '@/components/ui/label';
import { Checkbox } from '@/components/ui/checkbox';
import ObjectId from 'bson-objectid';
import { FormField } from '@/components/ui/form';
import { BillingNeodoc } from '@/components/billing/features/BillingNeodoc';
import { CheckboxCell, DateTimeCell } from '@/components/ui/cells';
import { Dialog, DialogClose, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { ButtonCopy } from '@/components/ui/button-copy';
import { toast } from 'react-toastify';
import { type FormType } from '@/types/api/forms';
import { ListBulletIcon } from '@radix-ui/react-icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { cn } from '@/lib/utils';
import type { PaginationState } from '@tanstack/react-table';
import { ButtonAsync } from '@/components/ui/button-async';
import provinces from '@/assets/locations/provinces.json';
import countries from '@/assets/locations/countries.json';
import { BindProps } from '@/components/utils/BindProps';

type ConfirmOrgInfos = Record<'organization' | 'username' | 'password', string>;
interface OrgFormValues {
    org_id: string;
    form_ids: string[];
    sync: boolean;
}

const features = [
    {
        label: combine('settings.organizations.features.psj'),
        value: Feature.NEODESK,
        Component: BillingNeodesk
    },
    {
        label: combine('settings.organizations.features.neoform'),
        value: Feature.NEOFORM,
        Component: BillingNeoform
    },
    {
        label: combine('settings.organizations.features.neodoc'),
        value: Feature.NEODOC,
        Component: BillingNeodoc
    }
];

const FEATURE_MAPPING: Record<string, Feature[]> = {
    [Feature.NEODESK]: [Feature.NEODESK, Feature.PSJ, Feature.CALENDAR]
};

const REVERSE_FEATURE_MAPPING: Record<string, string[]> =
     Object.entries(FEATURE_MAPPING).reduce<Record<string, string[]>>((acc, [key, features]) => {
         features.forEach(feature => {
             if (!acc[feature]) {
                 acc[feature] = [];
             }
             acc[feature].push(key);
         });
         return acc;
     }, {});

const DateCellContract = DateTimeCell({
    format: 'DDD',
    className: (value) => cn(DateTime.now() > value.minus({ days: 90 }) && 'tw-text-destructive tw-font-medium')
});

interface OrgEntry extends Org {
    user_infos?: UserInfos;
}

interface OrgCreatedDialogProps {
    org?: ConfirmOrgInfos;
    open: boolean;
    onOpenChange?: React.Dispatch<boolean>;
}

function OrgCreatedDialog(props: OrgCreatedDialogProps) {
    const { t, ct } = useTranslation('settings.organizations');
    return (
        <Dialog open={props.open} onOpenChange={props.onOpenChange}>
            <DialogContent className="!tw-max-w-xl" >
                <DialogHeader>
                    <DialogTitle>
                        {t('org-created')}
                    </DialogTitle>
                </DialogHeader>
                <div className="tw-flex tw-flex-col tw-gap-3">
                    {Object.entries(props.org ?? {}).map(([key, value]) =>
                        <div key={key} className="tw-space-x-2">
                            <span className="tw-font-medium">
                                {t(key as keyof typeof props.org)}:
                            </span>
                            <span>{value}</span>
                            <ButtonCopy
                                className="tw-text-primary tw-border-none"
                                size="xs"
                                value={value}
                            />
                        </div>
                    )}
                </div>
                <DialogFooter>
                    <DialogClose asChild>
                        <Button>{ct('ok')}</Button>
                    </DialogClose>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}

export function Organizations() {
    const { t, to, ct } = useTranslation('settings.organizations');
    const { options } = useLanguage();
    const { lang } = useUserStore(state => ({ lang: state.lang }));
    const crudApi = useRef<CrudApi>();
    const schema = useMemo<CrudSchema<OrgEntry>>(
        () => [
            {
                id: 'active',
                type: CrudInputType.CHECKBOX,
                name: 'Active',
                create: false,
                columnDef: {
                    id: 'active',
                    accessorKey: 'active',
                    header: 'Active',
                    cell: CheckboxCell
                }
            },
            {
                id: 'sections.org',
                type: CrudInputType.HEADER,
                name: t('table.sections.org')
            },
            {
                id: 'name',
                type: CrudInputType.TEXT,
                name: t('table.name'),
                col: 6,
                required: true,
                update: false,
                columnDef: {
                    id: 'name',
                    header: t('table.name'),
                    accessorKey: 'name'
                }
            },
            {
                id: 'title',
                type: CrudInputType.TEXT,
                name: t('table.title'),
                col: 6,
                required: true,
                columnDef: {
                    id: 'title',
                    header: t('table.title'),
                    accessorKey: 'title'
                }
            },
            {
                id: 'env.notification_email',
                type: CrudInputType.TEXT,
                name: t('table.notification-email'),
                col: 6
            },
            {
                id: 'lang',
                type: CrudInputType.SELECT,
                name: ct('language'),
                col: 6,
                required: true,
                options,
                getOptionLabel: (opt) => opt.label,
                getOptionValue: (opt) => opt.value,
                defaultValue: lang
            },
            {
                id: 'is_elawyer',
                type: CrudInputType.CHECKBOX,
                name: t('table.is-elawyer')
            },
            {
                id: 'sections.user',
                type: CrudInputType.HEADER,
                name: t('table.sections.user'),
                update: false
            },
            {
                id: 'user_infos.firstname',
                type: CrudInputType.TEXT,
                name: t('table.firstname'),
                col: 6,
                update: false
            },
            {
                id: 'user_infos.lastname',
                type: CrudInputType.TEXT,
                name: t('table.lastname'),
                col: 6,
                update: false
            },
            {
                id: 'user_infos.email',
                type: CrudInputType.TEXT,
                name: t('table.email'),
                col: 6,
                update: false
            },
            {
                id: 'user_infos.role',
                type: CrudInputType.SELECT,
                name: t('table.role'),
                col: 3,
                options: clone(roleOptions as unknown as string[]),
                update: false
            },
            {
                id: 'user_infos.external_id',
                type: CrudInputType.TEXT,
                name: t('table.external-id'),
                col: 3,
                update: false,
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-boolean-literal-compare
                shouldDisplay: (form) => form.getValues('is_elawyer') === true
            },
            {
                id: 'sections.billing',
                type: CrudInputType.HEADER,
                name: t('table.sections.billing')
            },
            {
                id: 'contract.billing.invoice_to.name',
                type: CrudInputType.TEXT,
                name: t('table.name'),
                col: 6,
                required: true
            },
            {
                id: 'contract.billing.invoice_to.phone',
                type: CrudInputType.TEXT,
                name: t('table.phone'),
                col: 6,
                required: true
            },
            {
                id: 'contract.billing.invoice_to.email',
                type: CrudInputType.TEXT,
                name: t('table.email'),
                required: true
            },
            {
                id: 'contract.billing.invoice_to.address.line1',
                type: CrudInputType.TEXT,
                name: t('table.line1'),
                required: true
            },
            {
                id: 'contract.billing.invoice_to.address.city',
                type: CrudInputType.TEXT,
                name: t('table.city'),
                col: 6,
                required: true
            },
            {
                id: 'contract.billing.invoice_to.address.postal_code',
                type: CrudInputType.TEXT,
                name: t('table.postal-code'),
                col: 6,
                required: true
            },
            {
                id: 'contract.billing.invoice_to.address.state',
                type: CrudInputType.SELECT,
                creatable: true,
                name: t('table.state'),
                col: 6,
                required: true,
                options: provinces,
                getOptionLabel: (opt) => opt.name,
                getOptionValue: (opt) => opt.iso
            },
            {
                id: 'contract.billing.invoice_to.address.country',
                type: CrudInputType.SELECT,
                creatable: true,
                name: t('table.country'),
                col: 6,
                required: true,
                options: countries,
                getOptionLabel: (opt) => opt.name,
                getOptionValue: (opt) => opt.iso
            },
            {
                id: 'sections.contract',
                type: CrudInputType.HEADER,
                name: t('table.sections.contract')
            },
            {
                id: 'contract.start_date',
                type: CrudInputType.DATE,
                col: 6,
                name: t('table.contract-start-date'),
                defaultValue: DateTime.now().startOf('day')
            },
            {
                id: 'contract.end_date',
                type: CrudInputType.DATE,
                col: 6,
                name: t('table.contract-end-date'),
                create: false,
                update: false,
                columnDef: {
                    id: 'contract.end_date',
                    header: t('table.contract-end-date'),
                    accessorKey: 'contract.end_date',
                    cell: DateCellContract
                }
            },
            {
                id: 'contract.duration_months',
                type: CrudInputType.NUMBER,
                name: t('table.contract-duration-months'),
                col: 6,
                defaultValue: 12,
                min: 0
            },
            {
                id: 'contract.free_months',
                type: CrudInputType.SELECT,
                name: t('table.contract-free-months'),
                col: 6,
                defaultValue: 0,
                required: true,
                options: [...Array(13)].map((_, idx) => idx)
            },
            {
                id: 'contract.block_billing',
                type: CrudInputType.CHECKBOX,
                name: t('table.contract-block-billing'),
                defaultValue: false
            },
            {
                id: 'sections.products',
                type: CrudInputType.HEADER,
                name: 'Produits'
            },
            {
                id: 'env.features',
                type: CrudInputType.CUSTOM,
                defaultValue: [],
                render: ({ field }) => (<div className="tw-flex tw-items-center tw-gap-2">
                    {features.map((feature) => (
                        <div key={feature.value} className="tw-flex-1 tw-flex tw-items-center">
                            <Checkbox
                                id={feature.value}
                                checked={field.value?.includes(feature.value)}
                                onCheckedChange={(value) => {
                                    if (value) {
                                        field.onChange([...field.value, feature.value]);
                                    } else {
                                        field.onChange(field.value.filter(
                                            (f: Feature) => f !== feature.value)
                                        );
                                    }
                                }}
                            />
                            <Label className="tw-pl-2" htmlFor={feature.value}>
                                {to(feature.label)}
                            </Label>
                        </div>
                    ))}
                </div>),
                onChange: (form, selectedFeatures: Feature[]) => {
                    const invoiceFeatures: InvoiceFeature[] = form.getValues('contract.billing.features_invoice') || [];
                    const newInvoiceFeatures = selectedFeatures
                        .filter(feature => features.find(i => i.value === feature))
                        .map<InvoiceFeature>(
                            (feature) => (invoiceFeatures.find(inf => inf.name === feature) ?? {
                                name: feature,
                                rules: [{
                                    name: 'rule_0',
                                    definition: {
                                        ...(feature === Feature.PSJ && { license: null })
                                    },
                                    stages: []
                                }]
                            })
                        );
                    form.setValue('contract.billing.features_invoice', newInvoiceFeatures);
                }
            },
            {
                id: 'contract.billing.features_invoice',
                type: CrudInputType.CUSTOM,
                defaultValue: [],
                render: ({ field }) => {
                    const { to } = useTranslation();
                    const value: InvoiceFeature[] = field.value ?? [];
                    return (
                        <div className="tw-flex tw-flex-col tw-gap-6">
                            {value.map((invoiceFeature, idx) => {
                                const feature = features.find(f => f.value === invoiceFeature.name);
                                if (!feature) return null;
                                return (
                                    <div
                                        key={invoiceFeature.name}
                                        className="tw-flex tw-flex-col tw-gap-2"
                                    >
                                        <label className="tw-font-medium tw-text-sm">
                                            {to(feature?.label)}
                                        </label>
                                        <FormField
                                            name={`contract.billing.features_invoice.${idx}`}
                                            render={feature?.Component as any}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    );
                }
            }
        ],
        []
    );
    const DialogComponent = useMemo(
        () => CrudDialog<OrgEntry, '_id'>({
            className: 'md:tw-max-w-2xl'
        }), []
    );
    const [forms, setForms] = useState<FormType[]>([]);
    const groups = useMemo(
        () => forms.reduce<Record<string, TranslationObject>>(
            (obj, form) => {
                if (form.category) {
                    obj[form.category.name] = form.category.label;
                }
                return obj;
            }, {}
        ) ?? {},
        [forms]
    );
    const FormListDialog = useMemo(
        () => BindProps(CrudDialog<OrgFormValues, 'org_id'>(), {
            idKey: 'org_id',
            schema: [
                {
                    id: 'form_ids',
                    type: CrudInputType.SELECT,
                    name: ct('forms'),
                    options: forms,
                    multiple: true,
                    getOptionValue: (opt) => opt.id,
                    getOptionLabel: (opt) => to(opt.title),
                    groupBy: (opt) => to(groups[opt.category?.name] ?? 'default')
                },
                {
                    id: 'sync',
                    type: CrudInputType.CHECKBOX,
                    name: t('form-list.sync'),
                    defaultValue: false
                }
            ]
        }),
        [forms]
    );
    const [userConfirm, setUserConfirm] = useState<ConfirmOrgInfos>();
    const [userConfirmOpen, setUserConfirmOpen] = useState(false);
    const [orgFormsOpen, setOrgFormsOpen] = useState(false);
    const [orgForms, setOrgForms] = useState<OrgFormValues>();
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 10
    });

    useEffect(() => {
        getFormsMaster().then((res) => setForms(res.data));
    }, []);

    function handleSaveOrg(org: OrgEntry | Omit<OrgEntry, '_id'>) {
        const {
            active,
            description,
            name,
            title,
            lang,
            user_infos,
            contract,
            env,
            is_elawyer
        } = org;

        const org_data: Omit<Org, '_id'> = {
            active,
            lang,
            description,
            name,
            folder: name,
            title,
            is_elawyer,
            env: {
                features: env.features.reduce<Feature[]>(
                    (arr, feature) => {
                        if (FEATURE_MAPPING[feature]) {
                            return [...arr, ...FEATURE_MAPPING[feature]];
                        }
                        if (REVERSE_FEATURE_MAPPING[feature]) {
                            return arr;
                        }
                        return [...arr, feature as Feature];
                    },
                    []
                )
            },
            contract: {
                start_date: contract.start_date,
                duration_months: contract.duration_months,
                billing: {
                    start_date: (contract.start_date as DateTime).plus({ months: contract.free_months }),
                    invoice_to: contract.billing.invoice_to,
                    features_invoice: contract.billing.features_invoice.map((feature) => ({
                        ...feature,
                        rules: feature.rules.map((rule) => ({
                            ...rule,
                            ...(feature.name === Feature.NEODESK && {
                                definition: {
                                    license: rule.definition.license,
                                    users_roles: {
                                        in: rule.definition.license === 'professional'
                                            ? ['admin']
                                            : ['lawyer']
                                    }
                                }
                            }),
                            ...(feature.name === Feature.NEOFORM && {
                                definition: {
                                    first_form_save: true
                                }
                            })
                        }))
                    }))
                }
            }
        };

        const request = '_id' in org
            ? postUpdateOrg({ _id: org._id, ...org_data })
            : postCreateOrg({
                org: org_data,
                notify: env.notification_email,
                user_infos: user_infos?.email ? user_infos : null,
                is_externe: !!user_infos?.external_id
            });

        return request.then((res) => {
            const { user } = res.data || {};
            if (res.data?.user?.lang) {
                delete user.lang;
            }
            if (!('_id' in org)) {
                setUserConfirm(user);
                setUserConfirmOpen(true);
            }
            toast.success(ct('messages.success'));
        });
    }

    function handleCreateOrg(org: Omit<OrgEntry, '_id'>) {
        if (!features.some(f => org.env.features.includes(f.value))) {
            toast.error(t('no-feature-selected-error'));
            return Promise.reject();
        }
        return handleSaveOrg(org);
    }

    function handleUpdateOrg(org: OrgEntry) {
        if (!features.some(f => org.env.features.includes(f.value))) {
            toast.error(t('no-feature-selected-error'));
            return Promise.reject();
        }
        return handleSaveOrg(org);
    }

    function handleChangeOrgForms({ org_id, form_ids, sync }: OrgFormValues) {
        return postUpdateOrgForms({ org_id, forms_id: form_ids, sync })
            .then(() => {
                toast.success(ct('messages.success'));
                setOrgFormsOpen(false);
                crudApi.current?.refreshList();
            });
    }

    return (
        <>
            <CardHeader className="tw-max-w-sc">
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent>
                <CrudTable<OrgEntry, '_id'>
                    idKey="_id"
                    schema={schema}
                    apiRef={crudApi}
                    actions={({ row }) => <>
                        <Tooltip>
                            <TooltipTrigger asChild>
                                <div>
                                    <ButtonAsync
                                        variant="ghost" size="icon"
                                        onClick={async() => {
                                            const res = await getForms(row.original._id);
                                            setOrgForms({
                                                org_id: row.original._id,
                                                form_ids: res.data
                                                    .map<string>(f => f.ref_form_id as string)
                                                    .filter(Boolean),
                                                sync: false
                                            });
                                            setOrgFormsOpen(true);
                                        }}
                                    >
                                        <ListBulletIcon />
                                    </ButtonAsync>
                                </div>
                            </TooltipTrigger>
                            <TooltipContent>
                                {t('table.actions.form-list')}
                            </TooltipContent>
                        </Tooltip>
                    </>}
                    onRead={() => getOrganizations({
                        limit: pagination.pageSize,
                        offset: pagination.pageIndex * pagination.pageSize
                    }).then((res) => {
                        setRowCount(res.data.nb_rows);
                        setPagination({
                            pageSize: res.data.limit,
                            pageIndex: pagination.pageIndex
                        });
                        return res.data.data.map(o => ({
                            ...o,
                            active: Boolean(o.active),
                            contract: {
                                ...o.contract,
                                billing: {
                                    ...o.contract?.billing,
                                    features_invoice: o.contract?.billing.features_invoice.map(f => ({
                                        ...f,
                                        rules: f.rules.map(r => ({
                                            ...r,
                                            stages: r.stages.map(s => ({
                                                ...s,
                                                id: ObjectId().toHexString()
                                            }))
                                        }))
                                    }))
                                }
                            }
                        }));
                    })}
                    onUpdate={handleUpdateOrg}
                    onCreate={handleCreateOrg}
                    dialogComponent={DialogComponent}
                    state={{ pagination }}
                    rowCount={rowCount}
                    onPaginationChange={setPagination}
                    manualPagination
                />
            </CardContent>
            <OrgCreatedDialog
                org={userConfirm}
                open={userConfirmOpen}
                onOpenChange={setUserConfirmOpen}
            />
            <FormListDialog
                value={orgForms}
                open={orgFormsOpen}
                translation={{
                    title: combine('settings.organizations.form-list.title'),
                    submit: combine('settings.organizations.form-list.submit')
                }}
                onOpenChange={setOrgFormsOpen}
                onSubmit={handleChangeOrgForms}
            />
        </>
    );
}
