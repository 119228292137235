import React from 'react';
import { type ControllerProps } from 'react-hook-form/dist/types/controller';
import { type FieldValues, useFieldArray } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { Cross1Icon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';

interface ListOptions<TContext> {
    addButtonText: string;
    className?: string;
    context?: TContext;
}

export function List<TFieldValues extends FieldValues = FieldValues, TContext = any>(
    Component: React.FC<{ name: string; context?: TContext }>,
    opts: ListOptions<TContext>
): ControllerProps<TFieldValues>['render'] {
    // eslint-disable-next-line react/display-name
    return ({ field }) => {
        const { fields, append, remove } = useFieldArray({ name: field.name });
        return (
            <div className={cn('tw-flex tw-flex-col tw-gap-4', opts.className)}>
                {fields.map((f, idx) =>
                    <div key={f.id} className="tw-flex tw-items-center">
                        <Button
                            variant="ghost" size="icon"
                            className="tw-text-destructive !tw-mr-1"
                            onClick={() => remove(idx)}
                        >
                            <Cross1Icon />
                        </Button>
                        <div className="tw-flex-1">
                            <Component
                                key={f.id}
                                name={`${field.name}.${idx}`}
                                context={opts.context}
                            />
                        </div>
                    </div>
                )}
                <Button
                    variant="outline" className="tw-w-full tw-text-primary"
                    onClick={() => append({} as any)}
                >
                    {opts.addButtonText}
                </Button>
            </div>
        );
    };
}
