import React, { useEffect } from 'react';
import { Col, Row } from '@/components/ui/row';
import { InputTranslate } from '@/components/ui/input-translate';
import { InputNumber } from '@/components/ui/input-number';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { useTranslation } from '@/composables/translation';
import { useValidation } from '@/composables/validation';
import { useFormContext } from 'react-hook-form';

interface Props {
    name: string;
}

export function PricingLevel(props: Props) {
    const form = useFormContext();
    const { ct } = useTranslation();
    const { required, requiredTranslate } = useValidation();
    useEffect(() => {
        return () => form.unregister(props.name);
    }, []);
    return (
        <Row>
            <Col col={6}>
                <FormField
                    name={`${props.name}.name`}
                    rules={{ validate: { requiredTranslate } }}
                    render={({ field }) =>
                        <FormItem>
                            <FormLabel>{ct('name')}</FormLabel>
                            <FormControl>
                                <InputTranslate {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    }
                />
            </Col>
            <Col col={6}>
                <FormField
                    name={`${props.name}.amount`}
                    rules={{ validate: { required } }}
                    render={({ field }) =>
                        <FormItem>
                            <FormLabel>{ct('amount')}</FormLabel>
                            <FormControl>
                                <InputNumber {...field} />
                            </FormControl>
                            <FormMessage />
                        </FormItem>
                    }
                />
            </Col>
        </Row>
    );
}
