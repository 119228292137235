import { type MutableRefObject } from 'react';
import _ from 'lodash';
import { type Language, LANGUAGES, useTranslation } from '@/composables/translation';
import {
    type FieldError,
    type FieldValues,
    type UseFormStateReturn
} from 'react-hook-form/dist/types';
import zod from 'zod';
import { getClientConflict } from './api';
import { type Party } from '@/types/api/party';
import { type ZodTypeAny } from 'zod/lib/types';

export type ValidationReturn = boolean | string;
export type ValidationRule = (value: any) => ValidationReturn | Promise<ValidationReturn>;
type ValidationRuleFn = (...args: any[]) => ValidationRule;

export const containsSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
export const containsNumber = /[0-9]/;
export const is8Chars = /.{8,}/;
export const containsUpperCase = /[A-Z]/;
export const containsLowerCase = /[a-z]/;

const emailRegex =
    // eslint-disable-next-line max-len
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

const postalCodeRegex =
    /^[ABCEGHJ-NPRSTVXY][0-9][ABCEGHJ-NPRSTV-Z][ -]?[0-9][ABCEGHJ-NPRSTV-Z][0-9]$/;

const phoneRegex =
    /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/;

const PORegex = /^(PO|P\.O\.|pO|p\.o\.|Po|P\.O\.|P\.O|p\.o)/i;

const translateSchema = zod.object(
    LANGUAGES.reduce(
        (obj, lang) => ({ ...obj, [lang]: zod.string() }),
        {}
    ) as Record<Language, ZodTypeAny>
)
    .partial()
    .refine((obj) => LANGUAGES.some(k => obj[k]));

export function useValidation() {
    const { t } = useTranslation('validation');
    function required(value: any) {
        return ((Array.isArray(value) && value.length > 0) ||
            (!Array.isArray(value) && (value === 0 || !!value))) ||
            t('required');
    }
    return {
        required,
        requiredFieldArray: (value: any) => !Array.isArray(value) ||
            value.filter(o => !o.deleted).length > 0 ||
            t('required'),
        requiredTranslate: (value: any) => translateSchema.safeParse(value).success || t('required'),
        email: (value: any) => (
            typeof value === 'string' && emailRegex.test(value.toLowerCase())
        ) || t('email'),
        postalCode: (value: any) => (
            typeof value === 'string' && postalCodeRegex.test(value)
        ) || t('postal-code'),
        phone: (value: any) => (
            typeof value === 'string' && phoneRegex.test(value)
        ) || t('phone'),
        po: (value: any) => (
            typeof value === 'string' && !PORegex.test(value)
        ) || t('po'),
        matchesOrEmpty: (regex: RegExp) =>
            (value: any) => !value || regex.test(value) || t('incomplete'),
        passwordMatches: (expected: MutableRefObject<any>) =>
            (value: any) => value === expected.current || t('password-match'),
        passwordSecure: (password: string) => {
            if (!is8Chars.test(password)) return t('8-chars');
            else if (!containsNumber.test(password)) return t('number');
            else if (!containsUpperCase.test(password)) return t('uppercase');
            else if (!containsLowerCase.test(password)) return t('lowercase');
            else if (!containsSpecialChar.test(password)) return t('special-char');
            return true;
        },
        partyConflict: (type = 'client') => (value: any) => {
            const id: string = typeof value === 'object' ? value._id : value;
            return getClientConflict({ party_id: id, party_type_name: type })
                .then((res) => !res.data.has_conflict_of_interest || t('conflict-of-interest'))
                .catch(() => true);
        },
        partyExists: (list: Party[]) => (value: any) => {
            const id: string = typeof value === 'object' ? value._id : value;
            return !list.find(p => p._id === id) || t('party-exists');
        },
        optional: (rule: ValidationRule) => (value: any) => !value || rule(value),
        maxLength: (length: number) => (value: any) =>
            (typeof value === 'string' && value.length <= length) || t('string.max', { length }),
        minLength: (length: number) => (value: any) =>
            (typeof value === 'string' && value.length >= length) || t('string.min', { length }),
        minArrayLength: (length: number) =>
            (value: any) => {
                const isRequired = required(value);
                if (typeof isRequired === 'string') {
                    return isRequired;
                }
                return (
                    Array.isArray(value) &&
                    value.filter(e => !e?.deleted).length >= length
                ) || t('array.min', { length });
            },
        maxArrayLength: (length: number) =>
            (value: any) => (
                Array.isArray(value) &&
                value.filter(e => !e?.deleted).length <= length
            ) || t('array.max', { length })
    } satisfies Record<string, ValidationRule | ValidationRuleFn>;
}

export function getFieldError<T extends FieldValues>(
    id: string,
    formState: UseFormStateReturn<T>
) {
    const error = _.get(formState.errors, id);
    return error?.message?.toString();
}

function listErrorsRecurse(
    errors: any,
    errorList: Array<FieldError & { id: string }>,
    path: string[] = []
) {
    for (const key of Object.keys(errors)) {
        const error = errors[key];
        if ('type' in error && 'message' in error && 'ref' in error) {
            errorList.push({ id: [...path, key].join('.'), ...error });
        } else {
            listErrorsRecurse(error, errorList, [...path, key]);
        }
    }
}

export function listErrors<T extends FieldValues>(errors: UseFormStateReturn<T>['errors']) {
    const errorList: Array<FieldError & { id: string }> = [];
    listErrorsRecurse(errors, errorList);
    return errorList;
}
