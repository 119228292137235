import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import SignatureCanvas from 'react-signature-canvas';
import { useDropzone } from 'react-dropzone';

import { type Language, useTranslation } from '@/composables/translation';
import { useLanguage } from '@/composables/language';
import { getUserSettings, postUpdateUser } from '@/composables/api';
import { useError } from '@/composables/error';

import { useUserStore } from '@/store/user';
import { downloadFileURL, newStateFromAction } from '@/composables/utils';
import { Button } from '@/components/ui/button';
import { ButtonSubmit } from '@/components/ui/button-submit';
import { CardContent, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import {
    Form,
    FormControl,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { Input } from '@/components/ui/input';
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue
} from '@/components/ui/select';
import { Switch } from '@/components/ui/switch';
import { Spinner } from '@/components/ui/spinner';
import { useValidation } from '@/composables/validation';
import { CheckIcon, Cross1Icon, DownloadIcon, UploadIcon } from '@radix-ui/react-icons';
import { cn } from '@/lib/utils';
import { Col, Row } from '@/components/ui/row';
import { Checkbox } from '@/components/ui/checkbox';
import { Label } from '@/components/ui/label';
import { TimePicker } from '@/components/ui/time-picker';
import { Combobox } from '@/components/ui/combobox';
import { DateTime } from 'luxon';
import { SectionLabel } from '@/components/ui/section-label';
import MicrosoftLogo from '@/assets/svg/logos/microsoft.svg';
import { OAuthRedirectButton } from '@/components/login/OAuthRedirectButton';
import { useMergeState } from '@/composables/merge';
import { Feature, useFeatures } from '@/composables/features';

const SIGNATURE_PAD_WIDTH = 500;
const SIGNATURE_PAD_HEIGHT = 300;

export function UserSettings() {
    const { t, ct } = useTranslation('settings.account');
    const { options } = useLanguage();
    const { isFeatureEnabled } = useFeatures();
    const { required } = useValidation();
    const {
        lang,
        setLang
    } = useUserStore(state => ({
        lang: state.lang,
        setLang: state.setLang
    }));
    const { handleNetworkError } = useError();
    const [{ microsoft }, setConnectedState] = useMergeState({
        microsoft: false,
        google: false
    });

    interface FormType {
        otp: boolean;
        firstname: string;
        lastname: string;
        lang: Language;
        timezone?: string;
        working_start?: DateTime;
        working_end?: DateTime;
        apply_book_limit?: boolean;
    }
    const defaultValues = {
        otp: false,
        firstname: '',
        lastname: '',
        lang
    };
    const form = useForm<FormType>({ defaultValues });
    const [loading, setLoading] = useState(false);
    const signaturePad = useRef<SignatureCanvas>(null);
    const { getInputProps, open } = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        onDrop: (acceptedFiles) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                const url = e.target?.result as string;
                if (url) {
                    signaturePad.current?.fromDataURL(url);
                }
            };
            const file = acceptedFiles[0];
            if (file) {
                reader.readAsDataURL(file);
            }
        }
    });

    function handleClearSignature() {
        signaturePad.current?.clear();
    }

    function handleSaveSignature() {
        const url = signaturePad.current?.toDataURL('image/png');
        if (url) {
            downloadFileURL('signature.png', url);
        }
    }

    function handleSubmit(data: FormType) {
        const signature = signaturePad.current?.toDataURL('image/png') ?? '';
        const id = useUserStore.getState().user?.user_id ?? '';
        return postUpdateUser({
            id,
            ...data,
            signature,
            working_start: data.working_start?.isValid
                ? data.working_start.toFormat('HH:mm:ss')
                : undefined,
            working_end: data.working_end?.isValid
                ? data.working_end.toFormat('HH:mm:ss')
                : undefined
        })
            .then((res) => {
                setLang(data.lang);
                toast(res.data.message, { type: res.data.updated ? 'success' : 'warning' });
            })
            .catch(handleNetworkError);
    }

    useEffect(() => {
        setLoading(true);
        getUserSettings()
            .then((res) => {
                const result = res.data;
                setLoading(false);
                form.reset({
                    firstname: result.firstname,
                    lastname: result.lastname,
                    lang: result.lang,
                    otp: result.otp,
                    timezone: result.timezone,
                    working_start: typeof result.working_start === 'string'
                        ? DateTime.fromFormat(result.working_start, 'HH:mm:ss')
                        : undefined,
                    working_end: typeof result.working_end === 'string'
                        ? DateTime.fromFormat(result.working_end, 'HH:mm:ss')
                        : undefined,
                    apply_book_limit: !!result.apply_book_limit
                });
                if (result.signature) {
                    setTimeout(() => {
                        signaturePad.current?.fromDataURL(result.signature, {
                            width: SIGNATURE_PAD_WIDTH,
                            height: SIGNATURE_PAD_HEIGHT
                        });
                    }, 500);
                }
            })
            .catch(handleNetworkError);
    }, []);

    return (
        <>
            <CardHeader>
                <CardTitle>
                    {t('title')}
                </CardTitle>
            </CardHeader>
            {loading && <CardContent className="tw-h-[20em] tw-flex tw-justify-center tw-items-center">
                <Spinner size="lg" className="tw-text-primary" />
            </CardContent>}
            {!loading && <Form {...form}>
                <form onSubmit={form.handleSubmit(handleSubmit)}>
                    <CardContent className="tw-flex tw-flex-col tw-gap-3 tw-overflow-x-auto tw-pb-4">
                        <div className="tw-flex tw-gap-2">
                            <FormField
                                name="firstname"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{ct('first-name')}</FormLabel>
                                        <FormControl>
                                            <Input id="firstname" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                            <FormField
                                name="lastname"
                                rules={{ validate: { required } }}
                                render={({ field }) =>
                                    <FormItem className="tw-flex-1">
                                        <FormLabel>{ct('last-name')}</FormLabel>
                                        <FormControl>
                                            <Input id="lastname" {...field} />
                                        </FormControl>
                                        <FormMessage/>
                                    </FormItem>
                                }
                            />
                        </div>
                        <FormField
                            name="lang"
                            rules={{ validate: { required } }}
                            render={({
                                field: {
                                    ref,
                                    ...field
                                }
                            }) =>
                                <FormItem className="tw-flex-1">
                                    <FormLabel>{ct('language')}</FormLabel>
                                    <FormControl>
                                        <Select {...field} onValueChange={field.onChange}>
                                            <SelectTrigger>
                                                <SelectValue id="lang" ref={ref}></SelectValue>
                                            </SelectTrigger>
                                            <SelectContent>
                                                {options.map(o =>
                                                    <SelectItem key={o.value} value={o.value}>
                                                        {o.label}
                                                    </SelectItem>
                                                )}
                                            </SelectContent>
                                        </Select>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                        <FormField
                            name="otp"
                            render={({ field }) =>
                                <FormItem className="tw-flex tw-items-center tw-gap-2 tw-mt-2">
                                    <FormControl>
                                        <Switch
                                            id="otp"
                                            {...field}
                                            checked={field.value}
                                            onCheckedChange={field.onChange}
                                        />
                                    </FormControl>
                                    <FormLabel className="!tw-mt-0">{ct('2fa')}</FormLabel>
                                    <FormMessage/>
                                </FormItem>
                            }
                        />
                        <SectionLabel>{t('signature.title')}</SectionLabel>
                        <div className="tw-flex tw-justify-center tw-items-center">
                            <div className={cn(
                                'tw-relative tw-rounded-sm tw-h-[302px]',
                                'tw-border-solid tw-border tw-border-black/30'
                            )}>
                                <input className="tw-hidden" {...getInputProps()} />
                                <SignatureCanvas
                                    ref={signaturePad}
                                    canvasProps={{
                                        width: SIGNATURE_PAD_WIDTH,
                                        height: SIGNATURE_PAD_HEIGHT
                                    }}
                                />
                                <div className={cn(
                                    'tw-absolute tw-w-[90%] tw-mx-[5%] tw-bottom-[40px]',
                                    'tw-border-solid tw-border-0 tw-border-b-[3px] tw-border-slate-700'
                                )}/>
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-end tw-gap-2">
                            <Button
                                type="button" variant="outline"
                                className="tw-text-destructive"
                                onClick={handleClearSignature}
                            >
                                <Cross1Icon className="tw-mr-2"/>
                                {t('signature.clear')}
                            </Button>
                            <Button
                                type="button" variant="outline"
                                className="tw-text-primary"
                                onClick={handleSaveSignature}
                            >
                                <DownloadIcon className="tw-mr-2"/>
                                {t('signature.save')}
                            </Button>
                            <Button
                                type="button" variant="outline"
                                className="tw-text-primary"
                                onClick={open}
                            >
                                <UploadIcon className="tw-mr-2"/>
                                {t('signature.upload')}
                            </Button>
                        </div>
                        <SectionLabel>{t('calendar.title')}</SectionLabel>
                        <Row>
                            <Col col={4}>
                                <FormField
                                    name="timezone"
                                    render={({ field: { ref, ...field } }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {t('calendar.timezone')}
                                            </FormLabel>
                                            <FormControl>
                                                <Combobox
                                                    options={Intl.supportedValuesOf('timeZone')}
                                                    getOptionLabel={(opt) => opt
                                                        .replaceAll('/', ' / ')
                                                        .replaceAll('_', ' ')
                                                    }
                                                    {...field}
                                                    innerRef={ref}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </Col>
                            <Col col={4}>
                                <FormField
                                    name="working_start"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {t('calendar.working-start')}
                                            </FormLabel>
                                            <FormControl>
                                                <TimePicker
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </Col>
                            <Col col={4}>
                                <FormField
                                    name="working_end"
                                    render={({ field }) => (
                                        <FormItem>
                                            <FormLabel>
                                                {t('calendar.working-end')}
                                            </FormLabel>
                                            <FormControl>
                                                <TimePicker
                                                    {...field}
                                                />
                                            </FormControl>
                                            <FormMessage/>
                                        </FormItem>
                                    )}
                                />
                            </Col>
                        </Row>
                        <FormField
                            name="apply_book_limit"
                            render={({ field }) => (
                                <FormItem>
                                    <FormControl>
                                        <div className="tw-flex">
                                            <Checkbox
                                                id="apply_book_limit"
                                                {...field}
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                            <Label
                                                htmlFor="apply_book_limit"
                                                className="tw-pl-2"
                                            >
                                                {t('calendar.apply-book-limit')}
                                            </Label>
                                        </div>
                                    </FormControl>
                                    <FormMessage/>
                                </FormItem>
                            )}
                        />
                        {isFeatureEnabled(Feature.EMAIL) && <>
                            <SectionLabel className="tw-pt-3">{t('email.title')}</SectionLabel>
                            <p className="tw-text-sm tw-text-muted-foreground">{t('email.description')}</p>
                            <div className="tw-flex tw-flex-wrap tw-gap-4 tw-pt-1">
                                <OAuthRedirectButton
                                    className="tw-w-64"
                                    oauth={{
                                        url: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize',
                                        scope: 'Mail.ReadWrite offline_access profile openid email User.Read',
                                        client_id: '86d74f08-48ae-47dc-b83b-36b6f2f9825c',
                                        provider: 'ms',
                                        plugin_name: 'user_email_extractor',
                                        redirect: '/settings/account'
                                    }}
                                    connected={microsoft}
                                    onConnectedChange={(value) => setConnectedState({
                                        microsoft: newStateFromAction(microsoft, value)
                                    })}
                                >
                                    <img
                                        className="tw-h-5 tw-mr-2"
                                        src={MicrosoftLogo}
                                        alt="Microsoft logo"
                                    />
                                    {microsoft
                                        ? t('email.buttons.microsoft.connected')
                                        : t('email.buttons.microsoft.not-connected')}
                                    {microsoft && <CheckIcon className="tw-size-4 tw-ml-2"/>}
                                </OAuthRedirectButton>
                                {/* <Button variant="outline"> */}
                                {/*     <img */}
                                {/*         className="tw-h-5 tw-mr-2" */}
                                {/*         src={GoogleLogo} */}
                                {/*         alt="Google logo" */}
                                {/*     /> */}
                                {/*     {t('email.buttons.google.not-connected')} */}
                                {/* </Button> */}
                            </div>
                        </>}
                    </CardContent>
                    <CardFooter className="tw-flex-col tw-gap-2">
                        <Button variant="link" className="!tw-px-0 tw-self-start" asChild>
                            <Link to="/settings/password">
                                {t('change-password')}
                            </Link>
                        </Button>
                        <ButtonSubmit className="tw-self-stretch">
                            {t('submit')}
                        </ButtonSubmit>
                    </CardFooter>
                </form>
            </Form>}
        </>
    );
}
