import React, { type HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import {
    DropdownMenu,
    DropdownMenuContent, DropdownMenuItem, DropdownMenuShortcut,
    DropdownMenuTrigger
} from '@/components/ui/dropdown-menu';
import { CheckIcon } from '@radix-ui/react-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cn } from '@/lib/utils';

interface Option {
    value: string;
    label: React.ReactNode;
    destructive?: boolean;
    selected?: boolean;
    icon?: IconProp;
    url?: string;
    onClick?: () => void;
}

interface Props {
    id: string;
    renderButton: (props: HTMLAttributes<HTMLButtonElement> & { ref: React.Ref<HTMLButtonElement> }) => React.ReactNode;
    tooltip?: string;
    options?: Option[];
}

export const MenuButton = React.forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const navigate = useNavigate();
    function handleItemClick(option: Option) {
        option.onClick?.();
        if (option.url) {
            navigate(option.url);
        }
    }
    const button = props.renderButton({ ref });
    const menu = (
        <DropdownMenu modal={false}>
            <DropdownMenuTrigger asChild>
                {props.tooltip
                    ? <TooltipTrigger asChild>
                        {button}
                    </TooltipTrigger>
                    : button
                }
            </DropdownMenuTrigger>
            <DropdownMenuContent className="tw-mx-2">
                {props.options?.map(o =>
                    <DropdownMenuItem
                        key={o.value}
                        className={cn(
                            o.destructive && 'tw-text-destructive',
                            o.destructive && 'focus:tw-text-destructive focus:tw-bg-destructive/10',
                            o.destructive && 'hover:tw-text-destructive hover:tw-bg-destructive/10'
                        )}
                        onClick={() => handleItemClick(o)}
                    >
                        {o.icon && <FontAwesomeIcon
                            className={cn(
                                'tw-mr-2 tw-text-muted-foreground',
                                o.destructive && '!tw-text-destructive'
                            )}
                            width="1em" icon={o.icon}
                        />}
                        {o.label}
                        {o.selected &&
                            <DropdownMenuShortcut>
                                <CheckIcon />
                            </DropdownMenuShortcut>
                        }
                    </DropdownMenuItem>
                )}
            </DropdownMenuContent>
        </DropdownMenu>
    );
    if (props.tooltip) {
        return (
            <Tooltip>
                {menu}
                <TooltipContent>
                    {props.tooltip}
                </TooltipContent>
            </Tooltip>
        );
    }
    return menu;
});
MenuButton.displayName = 'MenuButton';
