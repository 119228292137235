import type { View, ViewField, FieldType, Filter, Sort, Column } from '@/types/api/views';
import axios from 'axios';
import { useTranslation } from '@/composables/translation';
import { type IconProp } from '@fortawesome/fontawesome-svg-core';
import { faFolderOpen, faListCheck, faUser } from '@fortawesome/free-solid-svg-icons';
import { DateTime, Duration } from 'luxon';

export function useViews() {
    const { to } = useTranslation();
    function buildViewUrl(view: View) {
        return axios.getUri({
            baseURL: `/view/${view._id}`,
            params: {
                tab: to(view.title)
            }
        });
    }
    return {
        buildViewUrl
    };
}

export function getViewIcon(view: View): IconProp | undefined {
    switch (view.definition.url) {
    case '/dashboard':
        return faFolderOpen;
    case '/clients':
        return faUser;
    case '/forms':
        return faListCheck;
    }
    return undefined;
}

export function getViewFieldType(field: ViewField): FieldType {
    return (field.description?.type ?? field.type) as FieldType;
}

function formatDateValue(value: number, unit: 'days' | 'weeks' | 'months' | 'years') {
    const days = Duration.fromDurationLike({ [unit]: Math.abs(value) }).shiftTo('days').days;
    if (days === 0 || Number.isNaN(value)) {
        return '';
    }
    const isNegative = value < 0;
    return `${isNegative ? '-' : '+'}${days}`;
}

function compileDateValue(field: ViewField, value: any) {
    if (value instanceof DateTime) {
        return `${field.type === 'str' ? '<<strISO>>' : ''}${value.toISO()}`;
    }
    return '';
}

function compileValue(filter: Filter) {
    if (filter.field.description?.autofill_with === 'case_parties._id') {
        return filter.value?._id ?? '';
    }
    if (getViewFieldType(filter.field) === 'datetime') {
        return compileDateValue(filter.field, filter.value);
    }
    return filter.value;
}

function compileDateValueRelative(field: ViewField, value: number, unit: 'days' | 'weeks' | 'months' | 'years') {
    const formattedValue = formatDateValue(value, unit);
    return `${field.type === 'str' ? '<<strISO>>' : ''}{{date_now}}${formattedValue}`;
}

export function compileFilter(filter: Filter): any {
    const WITHIN_MAPPING = {
        $next: ['$gt', '$lt'],
        $last: ['$lt', '$gt']
    };
    if (filter.operator === '$next' || filter.operator === '$last') {
        return {
            [filter.field.path]: {
                [WITHIN_MAPPING[filter.operator][0]]:
                    compileDateValueRelative(filter.field, 0, 'days'),
                [WITHIN_MAPPING[filter.operator][1]]:
                    compileDateValueRelative(
                        filter.field,
                        filter.operator === '$last'
                            ? -Number(filter.value)
                            : Number(filter.value),
                        filter.unit
                    )
            }
        };
    }

    return {
        [filter.field.path]: {
            [filter.operator]: compileValue(filter)
        }
    };
}

export function compileView(
    filters_or: Filter[],
    filters_and: Filter[],
    sort: Sort[],
    columns: Column[]
): any {
    const filtersOrCompiled = filters_or.map(compileFilter);
    const filtersAndCompiled = filters_and.map(compileFilter);
    const sortCompiled = sort.reduce((obj, sort) => ({ ...obj, [sort.field.path]: sort.sort }), {});
    let rootFilter;
    if (filtersOrCompiled.length > 0) {
        if (filtersOrCompiled.length > 1) {
            rootFilter = { $or: filtersOrCompiled };
        } else {
            rootFilter = filtersOrCompiled[0];
        }
    }
    if (filtersAndCompiled.length > 0) {
        if (filtersAndCompiled.length > 1) {
            rootFilter = {
                $and: [
                    ...filtersAndCompiled,
                    ...(rootFilter ? [rootFilter] : [])
                ]
            };
        } else {
            rootFilter = filtersAndCompiled[0];
        }
    }
    if (!rootFilter) {
        rootFilter = {};
    }
    return {
        filters: rootFilter,
        sort: sortCompiled,
        columns
    };
}
