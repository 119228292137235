import React, { useMemo, useState } from 'react';
import type { ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { Combobox } from '@/components/ui/combobox';
import { getFieldError } from '@/composables/validation';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { Button } from '@/components/ui/button';
import { cn } from '@/lib/utils';
import { Pencil2Icon, PlusIcon } from '@radix-ui/react-icons';
import { type Activity, type UpdateActivityDto, useActivitySchema } from '@/types/api/activity';
import { combine, LANGUAGES, useTranslation } from '@/composables/translation';
import { CrudDialog } from '@/components/ui/crud-table';
import { postCreateActivity, putUpdateActivity } from '@/composables/api';
import { toast } from 'react-toastify';
import { BindProps } from '@/components/utils/BindProps';

interface Props<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends React.ComponentProps<ControllerProps<TFieldValues, TName>['render']> {
    activities: Activity[];
}

export function ActivitySelect<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>(
    { field: { ref, ...field }, formState, activities }: Props<TFieldValues, TName>
) {
    const { to, ct } = useTranslation();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<UpdateActivityDto>();
    const schema = useActivitySchema();
    const CreateActivityDialog = useMemo(
        () => BindProps(CrudDialog<UpdateActivityDto, '_id'>(), { idKey: '_id', schema }),
        [schema]
    );
    const isEdit = field.value && field.value.type === 'time-activity';
    return (
        <>
            <div className="tw-flex">
                <Combobox<Activity, Activity>
                    className="!tw-rounded-r-none"
                    options={activities}
                    getOptionLabel={(opt) => to(opt.title)}
                    getValueLabel={(opt) => to(opt.title)}
                    creatable
                    clearable
                    onCreate={(value) => {
                        setValue({
                            title: LANGUAGES.reduce(
                                (obj, lang) => ({ ...obj, [lang]: value }),
                                {}
                            ),
                            name: value
                        } as UpdateActivityDto);
                        setOpen(true);
                    }}
                    {...field}
                    innerRef={ref}
                    error={!!getFieldError(field.name, formState)}
                />
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            type="button" variant="outline"
                            className={cn(
                                '!tw-px-3 tw-h-[36px] !tw-rounded-l-none',
                                'tw-relative tw-left-[-1px] tw-text-muted-foreground'
                            )}
                            onClick={() => {
                                setValue(isEdit ? (field.value ?? undefined) : undefined);
                                setOpen(true);
                            }}
                        >
                            {isEdit
                                ? <Pencil2Icon/>
                                : <PlusIcon />
                            }
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                        {isEdit ? ct('edit') : ct('add')}
                    </TooltipContent>
                </Tooltip>
            </div>
            <CreateActivityDialog
                open={open}
                onOpenChange={setOpen}
                value={value}
                isUpdateDialog={isEdit}
                onSubmit={(value) =>
                    (value._id ? putUpdateActivity : postCreateActivity)(value)
                        .then((res) => {
                            field.onChange(res.data.activity);
                            setOpen(false);
                            setValue(undefined);
                            toast(ct('messages.success'), { type: 'success' });
                        })
                }
                translation={{
                    title: isEdit
                        ? combine('psj.time-log.activities.dialog.update-title')
                        : combine('psj.time-log.activities.dialog.create-title')
                }}
            />
        </>
    );
}
