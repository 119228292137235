import React from 'react';
import { Button } from '@/components/ui/button';
import { CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/components/ui/card';
import { useTranslation } from '@/composables/translation';
import { useNavigate } from 'react-router-dom';

export function Forbidden() {
    const { t, ct } = useTranslation('forbidden');
    const navigate = useNavigate();
    return (
        <>
            <CardHeader>
                <CardTitle>{t('title')}</CardTitle>
            </CardHeader>
            <CardContent className="tw-flex tw-flex-col tw-gap-3">
                <CardDescription>{t('description')}</CardDescription>
                <CardDescription>{ct('contact-admin')}</CardDescription>
            </CardContent>
            <CardFooter>
                <Button
                    className="!tw-p-0 !tw-h-4" variant="link"
                    onClick={() => navigate(-1)}
                >
                    {t('back')}
                </Button>
            </CardFooter>
        </>
    );
}
