import type React from 'react';
import { createContext } from 'react';
import {
    type NeoFormLayout,
    type NeoFormMeta,
    type NeoFormPDFOption,
    type NeoFormPermissionList,
    type NeoFormStatus
} from '@/types/neoform';
import { type TranslationObject } from '@/composables/translation';
import { type AxiosResponse } from 'axios';
import { type SubmitHandler } from 'react-hook-form';

export interface FormInfo {
    is_public: boolean;
    ticket_id: string;
    task_id: string;
    form_id: string;
    case_id?: string;
    target: string;
    name: string;
    readonly?: boolean;
    title?: TranslationObject;
    description?: TranslationObject;
    actions: NeoFormPDFOption[];
    permissions?: NeoFormPermissionList;
    preview_open?: boolean;
    preview_url?: string;
    preview_count?: number;
    status: Record<'lawyer' | string, NeoFormStatus>;
}

export interface FormFunction {
    dependencies: string[];
    fn: Function;
}

export interface FormSaveConfig {
    document_type?: string;
    captcha_token?: string;
}

export interface Context {
    loading: boolean;
    layout?: NeoFormLayout;
    meta?: NeoFormMeta;
    info?: FormInfo;
    dynamicComponents: Set<string>;
    functions: Record<string, FormFunction>;
    encrypt: RegExp[];
    setLoading: (loading: boolean) => void;
    setLayout: (layout: NeoFormLayout) => void;
    setMeta: (key: string, value: any) => void;
    setInfo: (value: Partial<FormInfo>) => void;
    ref: <T = any>(key: string, value: T) => void;
    get: <T = any>(key: string) => T;
    set: <T = any>(key: string, value: T) => void;
    watch: <T = any>(key: string) => T;
    saveForm: (config?: FormSaveConfig) => Promise<AxiosResponse>;
    submitForm: () => void;
    handleSubmit: (
        onValid: SubmitHandler<any>,
        bypassValidation?: boolean
    ) => (e?: React.BaseSyntheticEvent) => Promise<void>;
    forceUpdate: () => void;
}

export const NeoFormContext = createContext<Context | undefined>(undefined);
