import React, { useEffect, useState } from 'react';
import { type NeoFormComponentProps } from '@/components/neoform/NeoFormComponent';
import { useNeoForm } from '@/composables/neoform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { type TranslationObject, useTranslation } from '@/composables/translation';
import { Button } from '@/components/ui/button';
import { Dialog, DialogHeader, DialogContent, DialogTitle } from '@/components/ui/dialog';
import { type ScheduleTaken } from '@/types/api/calendar';
import { CalendarIcon } from 'lucide-react';
import { DateTime } from 'luxon';
import { FormField, FormMessage } from '@/components/ui/form';

interface Props {
    label: TranslationObject;
    switch?: boolean;
    color?: string;
}

export function InputBookingCalendar(props: NeoFormComponentProps & Props) {
    const [open, setOpen] = useState(false);
    const { hookForm, getChildFieldName, id, form } = useNeoForm();
    const { to } = useTranslation();
    const booking_token = hookForm.watch(getChildFieldName('token'));
    const link = `${window.location.origin}/calendar/public/${booking_token}`;
    const childFieldName = getChildFieldName('booking_data');

    useEffect(() => {
        if (booking_token) {
            window.addEventListener('message', bookingDataListener);
        }
        return () => window.removeEventListener('message', bookingDataListener);
    }, [booking_token]);

    const bookingDataListener = (e: any) => {
        if (!['https://stg.neodoc.app', 'https://www.neodoc.app', 'http://localhost:3000'].includes(e.origin)) return;
        if (typeof e.data !== 'string') return;
        const data = JSON.parse(e?.data) ?? {};
        if (data?.name === 'booking') {
            hookForm.setValue(childFieldName, data);
            setOpen(false);
        }
    };

    const taken = hookForm.watch(childFieldName) as ScheduleTaken || null;

    return (
        <FormField
            name={id}
            rules={{
                validate: {
                    required: (val: any) =>
                        !form?.info?.is_public || !!val?.booking_data ||
                        to({ en: 'Field required', fr: 'Champ requis' })
                }
            }}
            render={({ field }) =>
                <>
                    <Button
                        className='tw-mr-2'
                        color={props.color}
                        disabled={!booking_token || Boolean(taken)}
                        onClick={() => setOpen(!open)}
                    >
                        <FontAwesomeIcon icon={faCalendar} className='tw-mr-2'/>
                        {to({ en: 'Book an appointment', fr: 'Prendre RDV' })}
                    </Button>
                    <FormMessage />
                    <TakenInfo taken={taken} />
                    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
                        <DialogContent className="tw-h-screen md:tw-max-w-6xl !tw-flex tw-flex-col">
                            <DialogHeader>
                                <DialogTitle>
                                    {to({ en: 'Book an appointment', fr: 'Prendre RDV' })}
                                </DialogTitle>
                            </DialogHeader>
                            <iframe
                                src={link}
                                className='tw-w-full tw-min-h-96 tw-h-full tw-border-none tw-rounded-lg'
                            />
                        </DialogContent>
                    </Dialog>
                </>
            }
        />
    );
}

interface TakenInfoProps {
    taken: ScheduleTaken;
}

function TakenInfo(props: TakenInfoProps) {
    const { taken } = props;
    if (!taken) return null;
    return (
        <div className="tw-inline-flex tw-items-center">
            <CalendarIcon className="tw-mr-2 tw-size-[1.1rem]" />
            <span>
                {DateTime.fromISO(taken.start).toFormat('ff')}
                {' '}&mdash;{' '}
                {taken.title}
            </span>
        </div>
    );
}
