import Resizer from 'react-image-file-resizer';
import mime from 'mime-types';
import axios from 'axios';
import { redirect } from 'react-router-dom';
import { type Language } from '@/composables/translation';
import { type LoaderFunction } from '@remix-run/router/utils';
import { DateTime } from 'luxon';
import type React from 'react';

export function isURL(value: string) {
    return /https?:\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%\-/]))?/.test(value);
}

export function isJWT(token: string) {
    return /^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$/.test(token);
}

export function isEmail(email: string) {
    // eslint-disable-next-line max-len
    return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/.test(email);
}

export function parseJWT(token: string) {
    const base64Url = token.split('.')[1] ?? '';
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const payload = decodeURIComponent(
        window.atob(base64).split('')
            .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
    );
    try {
        return JSON.parse(payload);
    } catch (err) {
        return null;
    }
}

export function parseJSON<T = any>(json: string, defaultValue: any = null): T {
    try {
        return JSON.parse(json);
    } catch (err) {
        return defaultValue;
    }
}

export function getNavigatorLanguage(): Language {
    const lang = navigator.language.substring(0, 2);
    if (lang === 'en' || lang === 'fr') return lang;
    return 'en';
}

export function delay(ms: number) {
    return new Promise<void>(resolve => setTimeout(resolve, ms));
}

export function clone<T extends object>(value: T) {
    if (typeof window.structuredClone === 'function') {
        return window.structuredClone<T>(value);
    }
    return JSON.parse(JSON.stringify(value)) as T;
}

export function resizeImage(file: Blob) {
    return new Promise<string>(resolve => {
        Resizer.imageFileResizer(
            file,
            700,
            900,
            'JPEG',
            60,
            0,
            (uri) => {
                resolve(uri as string);
            },
            'base64'
        );
    });
}

export function readFile(file: Blob) {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(file);
    });
}

export function processFile(file: Blob): Promise<string> {
    const mimeType = mime.lookup(file.name) as string;
    if (mimeType?.startsWith('image/') && file.size > 1000000) {
        return resizeImage(file);
    }
    return readFile(file);
}

export function openFileURL(url: string) {
    window.open(url, '_blank');
}

export function downloadFileBase64(filename: string, mime: string, base64: string) {
    downloadFileURL(filename, `data:${mime};base64,${base64}`);
}

export function downloadFileURL(filename: string, url: string) {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = filename;
    anchor.target = '_blank';
    anchor.click();
}

export function downloadArrayBuffer(buffer: ArrayBuffer, filename?: string) {
    const blob = new Blob([buffer]);
    downloadBlob(blob, filename);
}

export function removeAccents(str: string) {
    return str.normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
}

export function normalizeFileName(name: string, type: string) {
    const ext = mime.extension(type);
    return removeAccents(`${name}${ext ? `.${ext}` : ''}`.replaceAll(/\s+/g, '_'));
}

export function downloadBlob(file: Blob, filename?: string) {
    const url = URL.createObjectURL(file);
    downloadFileURL(filename ?? file.name, url);
    URL.revokeObjectURL(url);
}

export function downloadFileFromUrl(url: string, filename?: string) {
    return axios.get(url, { responseType: 'blob' })
        .then((res) => {
            downloadBlob(res.data, filename);
        });
}

export function guessFileType(name: string): string {
    return mime.lookup(name) || 'application/octet-stream';
}

export function pushReturnUrl(url: string) {
    localStorage.setItem('return_url', url);
}

export function popReturnUrl(fallback = '/dashboard'): string {
    const url = localStorage.getItem('return_url') ?? fallback;
    localStorage.removeItem('return_url');
    return url;
}

export function redirectWithParams(to: string, original: URL) {
    const params = [...original.searchParams.entries()]
        .reduce((obj, [k, v]) => ({ ...obj, [k]: v }), {});
    const uri = axios.getUri({
        baseURL: to,
        params
    });
    return redirect(uri);
}

export function RedirectLoader(to: string): LoaderFunction {
    return ({ request }) => {
        const url = new URL(request.url);
        return redirectWithParams(to, url);
    };
}

export function cartesianProduct(...a: any[]): any[] {
    return a.reduce((a, b) => a.flatMap((d: any) => b.map((e: any) => [d, e].flat())));
}

export function isIFrame() {
    try {
        return window.self !== window.top;
    } catch (err) {
        return true;
    }
}

export function combineDateTime(date: DateTime, time: DateTime): DateTime {
    if (!date.isValid || !time.isValid) {
        return DateTime.invalid('cannot combine invalid dates');
    }
    const str = `${date.toFormat('yyyy-MM-dd')} ${time.toFormat('HH:mm')}`;
    return DateTime.fromFormat(str, 'yyyy-MM-dd HH:mm');
}

export function assignRef<T>(ref: React.ForwardedRef<T>, value: T) {
    if (isCallable<(instance: (T | null)) => void>(ref)) {
        ref(value);
    } else if (ref) {
        ref.current = value;
    }
}

export function hex2hsl(hex: string): string {
    const regex = /#([a-f0-9]{1,2})([a-f0-9]{1,2})([a-f0-9]{1,2})/;
    const match = hex.match(regex);
    if (!match) {
        return '0 100% 0%';
    }

    const r = parseInt(match[1].length === 2 ? match[1] : match[1].repeat(2), 16) / 255;
    const g = parseInt(match[2].length === 2 ? match[2] : match[2].repeat(2), 16) / 255;
    const b = parseInt(match[3].length === 2 ? match[3] : match[3].repeat(2), 16) / 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = 0;
    let s: number;
    let l = (max + min) / 2;

    if (max === min) {
        h = s = 0;
    } else {
        const d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        switch (max) {
        case r:
            h = (g - b) / d + (g < b ? 6 : 0);
            break;
        case g:
            h = (b - r) / d + 2;
            break;
        case b:
            h = (r - g) / d + 4;
            break;
        }
        h /= 6;
    }

    s = s * 100;
    s = Math.round(s);
    l = l * 100;
    l = Math.round(l);
    h = Math.round(360 * h);

    return `${h} ${s}% ${l}%`;
}

export function isCallable<T>(fn: T | unknown): fn is T {
    return typeof fn === 'function';
}

export function newStateFromAction<T>(prev: T, action: React.SetStateAction<T>): T {
    if (isCallable<(prev: T) => T>(action)) {
        return action(prev);
    }
    return action;
}

export function getInputFileList(input: HTMLInputElement): File[] {
    return Array.from(input.files ?? []);
}

interface Range { start: number; end: number }

export function toRanges(list: number[], sort = true) {
    if (list.length === 0) {
        return [];
    }
    if (sort) {
        list = [...list].sort((a, b) => a - b);
    }
    const ranges: Range[] = [];
    let start = list[0];
    let end = start;
    for (let i = 1; i < list.length; i++) {
        if (list[i] === end + 1) {
            end = list[i];
        } else {
            ranges.push({ start, end });
            start = list[i];
            end = start;
        }
    }
    ranges.push({ start, end });
    return ranges;
}

export function toStringRanges(ranges: Range[]): string {
    return ranges
        .map(({ start, end }) => (end === start) ? String(start) : `${start}-${end}`)
        .join(', ');
}

export function toStringNumbersAsRanges(list: number[], sort = true) {
    return toStringRanges(toRanges(list, sort));
}

export function is<T>(value: unknown, type: T): value is NonNullable<T> {
    if (value == null) { return false; }
    return value instanceof (type as any);
}

export function getEventCoordinates(event: MouseEvent | TouchEvent) {
    if (event.type.startsWith('touch')) {
        const touch = (event as TouchEvent).touches[0] ?? (event as TouchEvent).changedTouches[0];
        return { x: touch.clientX, y: touch.clientY };
    }
    return {
        x: (event as MouseEvent).clientX,
        y: (event as MouseEvent).clientY
    };
}
